import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";
import { Form, Message, Loader, Grid, Radio, Input, Header } from "semantic-ui-react";
import { get } from "lodash";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";

import { ConfigContext, ConfigContextProvider } from "../../../contexts/appContexts";
import { BasicFormProps, Media, dateKey, timeKey, VideoProjectPlayerSummary } from "../../../interfaces";
import { useSocialNetworks } from "../../../reducers";
import { PSTimeZone, changeLocalDateToPST, isToday, validAge, nextWeekDay } from "../../../utils";

import { AppContext } from "../../../providers";
import { HashTagsServices, VideoProjectServices } from "../../../services";
import { MultiMediaUploaderComponent } from "../../MultiMediaUploader";
import defaultConfigProperties from "../../../defaultConfigProperties";

import { HashTagSet } from "./components/HashTagSet";
import { PredictionsComponent } from "./components/PredictionsComponent";

import { SocialNetworkIconButton } from "../../SocialNetworkIconButton";

import { RowBasicPadding, SocialGrid, SocialRowGrid, TitleLabel } from "../../../styling/baseStyle";
import { VideoProjectComponentType, descriptors } from "./descriptors";
import { PlayerDropDown } from "./components/PlayerDropDown";

import "./styles.scss";
import "../../../styling/datepicker.scss";

interface OwnProps extends BasicFormProps {
  /**
   * Handle attached file is uploaded
   */
  handleMediaUpdated: (newMedia: Media[]) => void;
  handleAttachedFileUploadStarted: () => void;
  handleAttachedFileUploadFinished: () => void;

  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
  /**
   * Handle cancel action
   */
  handleCancel?: () => void;

  selectedPlayer?: boolean;
  modeEditable: boolean;
  /**
   * To Indicate if the form is disabled
   */
  disabled: boolean;
  /**
   * To show or hide the Player Custom Hashtags Toggle
   */
  showHashtagToggle: boolean;
}

type Props = OwnProps;
const dataElmId = "createVideoProject";
/**
 * Represents a Prompt content.
 */
const VideoProjectClass: React.FC<Props> = ({
  handleSetFieldValue,
  handleAttachedFileUploadStarted,
  handleAttachedFileUploadFinished,
  handleMediaUpdated,

  messages,
  isSubmitting = false,
  selectedPlayer = false,
  values,
  disabled,
  showHashtagToggle,
}) => {
  const { firebase } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);
  const { sns } = useSocialNetworks();
  const { formatMessage } = useIntl();

  const coachId = get(userContext, "coach.id", "");
  const { socialNetworkMessages } = values;

  const [hashtags, setHashtags] = React.useState<string>(values.hashtags);
  const [showAIModal, setShowAIModal] = React.useState<boolean>(false);
  const [hashtagsListError, setHashtagsListError] = React.useState<string[] | undefined>();

  const errors = messages && messages.error && messages.error.length > 0;

  // Player section
  const [playerSummaries, setPlayerSummaries] = React.useState<VideoProjectPlayerSummary[]>([]);

  React.useEffect(() => {
    VideoProjectServices.getPlayerSummaries(coachId).then(players => {
      setPlayerSummaries(players);
    });
  }, []);

  const playerSummary = useMemo(() => {
    if (values.playerId) {
      return playerSummaries.find(summary => summary.playerId.toString() === values.playerId.toString());
    } else {
      return null;
    }
  }, [playerSummaries, values.playerId]);

  const playerList = useMemo(() => {
    return playerSummaries.map(player => ({
      key: player.playerId,
      text: player.playerName,
      value: player.playerId,
    }));
  }, [playerSummaries]);

  React.useEffect(() => {
    return () => {
      window.removeEventListener("loadedmetadata", () => {
        // Nothing
      });
    };
  }, []);

  React.useEffect(() => {
    const getDefaultHashtags = async () => {
      HashTagsServices.getCoachHashtags(coachId).then(response => {
        setHashtags(() => {
          return "#videocatalyst " + "#" + response.map(hashtag => hashtag.content).join(" #");
        });
      });
    };

    if (hashtags === "") {
      getDefaultHashtags();
    }
  }, [coachId]);

  const settingsSection = (): JSX.Element => {
    const { allowShortLink, allowHashtags, allowComplianceDisclaimer } = values;

    const settings: any[] = [];

    if (showHashtagToggle) {
      settings.push({
        label: formatMessage({ ...descriptors[VideoProjectComponentType.allowHashtags] }),
        checked: allowHashtags,
        id: "allowHashtags",
      });
    }

    settings.push({
      label: formatMessage({ ...descriptors[VideoProjectComponentType.allowShortLink] }),
      checked: allowShortLink,
      id: "allowShortLink",
    });

    settings.push({
      label: formatMessage({ ...descriptors[VideoProjectComponentType.allowComplianceDisclaimer] }),
      checked: allowComplianceDisclaimer,
      id: "allowComplianceDisclaimer",
    });

    return (
      <React.Fragment>
        <Grid padded>
          <Grid.Column floated="right" width={10}>
            <Form.Group grouped key={"videoProjectPermissions"} className={"floatedRight"}>
              {settings.map(appSetting => {
                return (
                  <div key={"videoProjectPermissions" + appSetting.id}>
                    <Form.Group inline key={appSetting.id} className={"floatedRight"}>
                      <label className="small">{appSetting.label}</label>
                      <Radio
                        toggle
                        className={"settingsLevelLabel"}
                        onChange={(e: any, data: any) => {
                          handleSetFieldValue!(data.name, data.checked);
                        }}
                        name={appSetting.id}
                        checked={appSetting.checked}
                      />
                    </Form.Group>
                  </div>
                );
              })}
            </Form.Group>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  };

  const dateSection = (dateEditable: boolean): JSX.Element => {
    moment.tz.setDefault(PSTimeZone);

    const { deliveryDateUtc, deliveryTimeUtc } = values;
    const minPostingTime = nextWeekDay(nextWeekDay());
    let postDate: Date;
    let postTime: Date;
    if (deliveryDateUtc) {
      postDate = new Date(deliveryDateUtc);
      postTime = new Date(deliveryDateUtc);

      const deliveryDate = moment(deliveryDateUtc).tz(PSTimeZone);
      postDate.setDate(deliveryDate.date());
      postDate.setMonth(deliveryDate.month());

      const deliveryTime = moment(deliveryTimeUtc).tz(PSTimeZone);
      postTime.setHours(deliveryTime.hours());
      postTime.setMinutes(deliveryTime.minutes());
    } else {
      postDate = minPostingTime;
      postTime = minPostingTime;
    }

    return (
      <SocialGrid className={"xbigPaddingTop xxbigPaddingLeft"}>
        <SocialRowGrid>
          <SocialGrid.Column width={8} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="calendar alternate outline" />}
              data-elm-id={dataElmId + "SelectedDateInput"}
              id={dateKey}
              name={dateKey}
              selected={postDate}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(dateKey, changeLocalDateToPST(date));
                  if (isToday(date)) {
                    handleSetFieldValue!(timeKey, undefined);
                  }
                }
              }}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              maxDate={validAge(minPostingTime, 1)}
              minDate={minPostingTime}
              dateFormat="EE, MM/dd/yyyy"
              disabled={!dateEditable}
              autoComplete={"off"}
              placeholderText={formatMessage({ ...descriptors[VideoProjectComponentType.datePlaceholder] })}
            />
          </SocialGrid.Column>
          <SocialGrid.Column width={8} className={"noPadding"}>
            <DatePicker
              customInput={<Input icon="clock outline" />}
              data-elm-id={dataElmId + "SelectedTimeInput"}
              id={timeKey}
              name={timeKey}
              selected={postTime}
              onChange={(date: any) => {
                if (date) {
                  handleSetFieldValue!(timeKey, changeLocalDateToPST(date));
                }
              }}
              showTimeSelect
              showTimeSelectOnly
              dateFormat="h:mm aa"
              autoComplete={"off"}
              disabled={!dateEditable}
              placeholderText={formatMessage({ ...descriptors[VideoProjectComponentType.timePlaceholder] })}
            />
          </SocialGrid.Column>
        </SocialRowGrid>
      </SocialGrid>
    );
  };

  /**
   * Return a custom Radio button with Black Style
   * @param id radio identifier
   * @param radioButtonDisabled if the radio is disabled
   * @param checked if the radio is on
   * @param onChange onChange handler
   * @param label Radio Label key on the descriptor file
   * @returns JSX.Element (Radio element)
   */
  const customRadioButton = (
    id: string,
    radioButtonDisabled: boolean | undefined,
    checked: boolean,
    onChange: (e: any, data: any) => void,
    label: string
  ): JSX.Element => {
    return (
      <Radio
        data-elm-id={`${dataElmId}${id}RadioBtn`}
        className={"blackRadio secondary rounded"}
        id={id}
        name={id}
        onChange={onChange}
        checked={checked}
        disabled={radioButtonDisabled}
        label={formatMessage({ ...descriptors[VideoProjectComponentType[label]] })}
      />
    );
  };

  const autoPostRadio = (): JSX.Element => {
    return customRadioButton(
      "isAutoPost",
      false,
      values.isAutoPost,
      (e: any, data: any) => {
        handleSetFieldValue!(`${data.id}`, data.checked);
      },
      "autoPost"
    );
  };

  /*
   * Auto Post or Push Notification
   */
  const deliveryType = (): JSX.Element => {
    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <SocialGrid.Column width={10}>
          <RowBasicPadding>
            <TitleLabel>{formatMessage({ ...descriptors[VideoProjectComponentType.postSectionTitle] })}</TitleLabel>
            <br />
          </RowBasicPadding>

          <SocialRowGrid className={"bigPaddingTop"} leftpadding={15} key={"post_row_auto_post"}>
            {autoPostRadio()}
            {values.isAutoPost && (
              <SocialGrid className={"autoPostSection"}>
                {dateSection(true)}
                <RowBasicPadding className={"socialIconsSection"}>
                  <SocialGrid className={"socialSection"} divided={"vertically"} columns={6}>
                    {sns.map((sn, index) => {
                      return (
                        <SocialNetworkIconButton
                          disabled={disabled}
                          withPadding={false}
                          size={"noMargin"}
                          key={"video_project_post_sn_icon_" + index}
                          active={socialNetworkMessages ? socialNetworkMessages[sn] : false}
                          networkId={sn}
                          customIconClassname={"socialNetworkIconCentered"}
                          onClickHandler={checked => {
                            const snUpdated = { ...socialNetworkMessages, [sn]: checked };
                            handleSetFieldValue!(
                              "socialNetworksCounter",
                              Object.values(snUpdated).filter(Boolean).length
                            );
                            handleSetFieldValue!("socialNetworkMessages", { ...socialNetworkMessages, [sn]: checked });
                          }}
                        />
                      );
                    })}
                  </SocialGrid>
                </RowBasicPadding>
              </SocialGrid>
            )}
          </SocialRowGrid>

          <SocialRowGrid key={"post_row_push_notification"}>
            {customRadioButton(
              "isPushPost",
              false,
              !values.isAutoPost,
              () => {
                handleSetFieldValue!("isAutoPost", false);
              },
              "pushNotification"
            )}
          </SocialRowGrid>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  const captionSection = (
    <Form.Field>
      <div>
        <label>{formatMessage({ ...descriptors[VideoProjectComponentType.message] })}</label>
        <a className="aiButton" onClick={() => setShowAIModal(true)}>
          {formatMessage({ ...descriptors[VideoProjectComponentType.aiButton] })}
        </a>
      </div>
      <TextareaAutosize
        data-elm-id={dataElmId + "CaptionInput"}
        id="messageToPost"
        name="messageToPost"
        className="captionField"
        placeholder={formatMessage({ ...descriptors[VideoProjectComponentType.messagePlaceholder] })}
        rows={3}
        disabled={disabled}
        value={values.messageToPost}
        onChange={data => {
          handleSetFieldValue!("withMessage", data.currentTarget.value + "\n" + hashtags);
          handleSetFieldValue!("messageToPost", data.currentTarget.value);
        }}
      />
    </Form.Field>
  );

  const specialRequestSection = (
    <Form.Field>
      <div className="smallPaddingBottom">
        <label>{formatMessage({ ...descriptors[VideoProjectComponentType.specialRequests] })}</label>
      </div>
      <TextareaAutosize
        data-elm-id={dataElmId + "SpecialRequestInput"}
        id="initialMessages"
        name="initialMessages"
        className="captionField"
        placeholder={formatMessage({ ...descriptors[VideoProjectComponentType.specialRequestsPlaceholder] })}
        rows={3}
        disabled={disabled}
        value={
          values.initialMessages && values.initialMessages.length > 0 ? values.initialMessages[0].content : undefined
        }
        onChange={data => {
          handleSetFieldValue!("initialMessages", [{ content: data.currentTarget.value }]);
        }}
      />
    </Form.Field>
  );

  const hashtagTextField = (
    <>
      <label>{formatMessage({ ...descriptors[VideoProjectComponentType.hashtags] })}</label>
      {hashtagsListError && hashtagsListError.length > 0 && (
        <Grid className={"paddingTop leftPadding"}>
          {hashtagsListError.map(error => (
            <Grid.Row className={"noPadding"} columns={1}>
              <div className="labelWithError">{error}</div>
            </Grid.Row>
          ))}
        </Grid>
      )}
      <Grid className={"marginBottom " + (hashtagsListError && hashtagsListError?.length > 0 ? "" : "paddingTop")}>
        <HashTagSet
          title={"Hashtags..."}
          coachId={coachId}
          onError={setHashtagsListError}
          customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
          defaultHashtagList={hashtags}
          onSuccess={newTags => {
            const hashtagsString = newTags.reduce(
              (accumulator, hashtag) => accumulator + "#" + hashtag.displayValue + " ",
              ""
            );
            if (newTags.length <= 30) {
              handleSetFieldValue!("hashtags", hashtagsString);
              handleSetFieldValue!("withMessage", values.messageToPost + "\n" + hashtagsString);
            }
          }}
        />
      </Grid>
    </>
  );

  return (
    <>
      <Form size="large" error={errors} className={"videoProjectFormContainer"} disabled={disabled}>
        {isSubmitting && <Loader active size="large" />}

        <div key={"videoProjectFormDiv"} className={disabled ? "whiteFormDiv disabledForm" : "whiteFormDiv"}>
          {messages && messages.error && messages.error.length > 0 && (
            <Message error list={messages && messages.error} />
          )}

          {/* IF PLAYER IS NOT SELECTED INCLUDE PLAYER DROPDOWN */}
          <div className={"playerSection"}>
            <label>{formatMessage({ ...descriptors[VideoProjectComponentType.playerSection] })}</label>
            <br />
            <br />
            {!selectedPlayer && playerList && playerList?.length > 0 && (
              <PlayerDropDown
                options={playerList}
                placeholder={"Player"}
                value={values.playerId ? Number(values.playerId) : null}
                onChangeHandler={data => {
                  handleSetFieldValue!("playerId", data);
                }}
              />
            )}
            {playerSummary && (
              <div>
                {selectedPlayer && (
                  <Header as="h4" textAlign="left" className="noMargin noPadding">
                    {playerSummary.playerName}
                  </Header>
                )}
                <div className={"playerSummary"}>{playerSummary.playerEmail}</div>
                <div className={"playerSummary"}>
                  Video Credits Remaining:{" "}
                  {playerSummary.unlimitedCredits ? "Unlimited" : playerSummary.creditsRemaining}
                </div>
              </div>
            )}
          </div>

          {/* Files section */}
          <div className={"attachVideosSection"}>
            <label>{formatMessage({ ...descriptors[VideoProjectComponentType.attachDesc] })}</label>
            <ConfigContextProvider value={defaultConfigProperties}>
              <MultiMediaUploaderComponent
                coachId={coachId}
                firebase={firebase}
                handleMediaUpdated={handleMediaUpdated}
                handleAttachedFileUploadFinished={handleAttachedFileUploadFinished}
                handleAttachedFileUploadStarted={handleAttachedFileUploadStarted}
                originalMedia={values.originalMediaGroup?.media || []}
              />
            </ConfigContextProvider>
          </div>

          {/* CAPTION */}
          {captionSection}
          <br />

          {/* Hashtags */}
          {hashtagTextField}

          {/* Settings */}
          {settingsSection()}

          {/* Post Preferences */}
          {deliveryType()}

          {/* Special Requests  */}
          {specialRequestSection}
        </div>
      </Form>

      {showAIModal && (
        <PredictionsComponent
          opened={true}
          rejectHandler={() => {
            setShowAIModal(false);
          }}
          acceptHandler={(prediction, addedHashtags) => {
            setShowAIModal(false);
            handleSetFieldValue!("messageToPost", prediction);

            if (addedHashtags && addedHashtags.length > 0) {
              const newTags: string[] = [];
              if (hashtags.length > 0) {
                hashtags.split(" ").forEach(tag => newTags.push(tag));
              }

              addedHashtags.forEach(tag => {
                if (newTags.indexOf(tag) === -1) {
                  newTags.push(tag);
                }
              });
              setHashtags(newTags.join(" "));
            }
          }}
        />
      )}
    </>
  );
};

export const VideoProjectComponent = VideoProjectClass;
