import * as React from "react";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Dimmer, Grid, Header, Loader } from "semantic-ui-react";
import Webcam from "react-webcam";
import { get } from "lodash";

import { URLS } from "../../../utils";

import { useInterval } from "./hooks";
import { ControlSection } from "./components/ControlsSection";
import { RecordActionSection } from "./components/RecordActionsSection";

import { descriptors, RecordPageType } from "./descriptors";
import { ScriptTextArea, SocialCoachWebCam, WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";

interface RecordProps {
  title: string;
}

type Props = RouteComponentProps;

const MAX_SCROLL = 6;
const AUTO_SCROLL = 100;
const ASPECT_RATIO = 9 / 16;
const dataElmId = "recordVideoContainer";

const RecordVideoFC: React.FC<Props> = ({ history, location }) => {
  const { formatMessage } = useIntl();

  const [speed, setSpeed] = React.useState<number>(0.6);
  const [textSize, setTextSize] = React.useState<number>(24);
  const [camera, setCamera] = React.useState<string | undefined>(undefined);
  const [microphone, setMicropone] = React.useState<string | undefined>(undefined);

  // Camera
  const [previewUrl, setPreviewUrl] = React.useState<string | undefined>(undefined);
  const webcamRef = React.useRef<Webcam>(null);

  // Scroll
  const scrollToRef = React.useRef<HTMLTextAreaElement>(null);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [autoScroll, setAutoScroll] = React.useState<number | undefined>();

  // Settings
  const [isRecording, setIsRecording] = React.useState<boolean>(false);
  const [withPermissions, setWithPermissions] = React.useState<boolean | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);

  const scriptMessage = get(location, "state.script", "");
  const scriptId = get(location, "state.id", "");

  React.useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition, isRecording]);

  useInterval(() => {
    setScrollPosition(scrollPosition + MAX_SCROLL * speed);
  }, autoScroll);

  const stopScroll = (): void => {
    setAutoScroll(undefined);
    setIsRecording(false);
  };

  const startScroll = (): void => {
    setIsRecording(true);
    setAutoScroll(AUTO_SCROLL);
  };
  const pauseScroll = (): void => {
    setScrollPosition(scrollPosition);
    setIsRecording(false);
    setAutoScroll(undefined);
  };

  function RecordTitleSection({ title }: RecordProps) {
    return (
      <Grid.Row className="titleSection">
        <Header as="h2">{title}</Header>
      </Grid.Row>
    );
  }

  const gotToVideoProject = (finalUrl: string) => {
    history.push(URLS.player.newVideoProject, { preloadVideo: finalUrl, scriptId });
  };

  const backAction = (): void => {
    if (isRecording) {
      if (window.confirm(formatMessage({ ...descriptors[RecordPageType.confirmationMessage] }))) {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const videoConstraints = {
    facingMode: "user",
    deviceId: camera,
    aspectRatio: ASPECT_RATIO,
    height: 500,
  };

  return (
    <WhiteContainer>
      <Grid className="hugePadding">
        {/* Headers */}
        <Grid.Row>
          <Grid.Column width={4}>
            <Button secondary data-elm-id={`${dataElmId}BackBtn`} onClick={backAction}>
              {formatMessage({ ...descriptors[RecordPageType.backBtn] })}
            </Button>
          </Grid.Column>

          <RecordActionSection
            startOverHandler={() => setScrollPosition(0)}
            disabled={!withPermissions}
            webcamRef={webcamRef}
            pauseScroll={pauseScroll}
            startScroll={startScroll}
            stopScroll={stopScroll}
            loadPreview={setPreviewUrl}
            doneHandler={gotToVideoProject}
            loadingHandler={setLoading}
          />
        </Grid.Row>

        {/* Controls, script and video */}
        <Grid.Row>
          {/* Controls */}
          <Grid.Column width={4}>
            <Grid className="recordSectionGrid">
              <RecordTitleSection title={formatMessage({ ...descriptors[RecordPageType.controlsTitle] })} />
              <ControlSection
                onChangeSpeed={setSpeed}
                onChangeTextSize={setTextSize}
                onChangeCamera={setCamera}
                onChangeMicrophone={setMicropone}
              />
            </Grid>
          </Grid.Column>

          {/* Script */}
          <Grid.Column width={6}>
            <Grid className="recordSectionGrid">
              <RecordTitleSection title={formatMessage({ ...descriptors[RecordPageType.scriptTitle] })} />
              <Grid.Row>
                <ScriptTextArea
                  ref={scrollToRef}
                  fontsize={textSize}
                  key={"scriptText"}
                  data-elm-id={`${dataElmId}scriptTeleprompterText`}
                  id={"scriptText"}
                  value={scriptMessage}
                />
              </Grid.Row>
            </Grid>
          </Grid.Column>

          {/* Video */}
          <Grid.Column width={4}>
            <Grid className="recordSectionGrid">
              <RecordTitleSection title={formatMessage({ ...descriptors[RecordPageType.liveSectionTitle] })} />
              <Grid.Row>
                <>
                  <SocialCoachWebCam
                    display={(!previewUrl || isRecording) && withPermissions === true}
                    audio={true}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    audioConstraints={{ deviceId: microphone }}
                    onUserMediaError={() => {
                      setWithPermissions(false);
                    }}
                    onUserMedia={() => {
                      setWithPermissions(true);
                    }}
                  />
                  {!(!previewUrl || isRecording) && <video src={previewUrl} controls />}
                </>
                {!withPermissions && (
                  <Header as="h2" className="permissionError">
                    {formatMessage({ ...descriptors[RecordPageType.permissionsError] })}
                  </Header>
                )}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {loading && (
        <Dimmer active>
          <Loader indeterminate>Uploading…</Loader>
        </Dimmer>
      )}
    </WhiteContainer>
  );
};

export const RecordVideoPage = withRouter(RecordVideoFC);
