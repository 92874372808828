import { Media, MediaGroup} from "../interfaces";

const mediaCategoryChecker = value => ["png", "jpg", "jpeg"].some(element => value.includes(element));

export function toMedia(url: string, index: number = 0, userId?: number): Media {
  const timestamp = new Date().getTime();
  const baseFileName: string = `brandFile_${userId}_${timestamp}`;
  const mediaCategory = mediaCategoryChecker(url) ? "IMAGE" : "VIDEO";

  const mediaObject: Media = {
    id: index,
    sortOrder: index,
    category: mediaCategory,
    fileName: baseFileName + index,
    uri: url,
    type: mediaCategory ? "image/png" : "video/mp4",
    extension: mediaCategory ? "png" : "mp4",
  };

  return mediaObject;
}

export function toMediaGroup(
  url: string,
  userId?: number,
  index: number = 0
): MediaGroup {
  const mediaType = mediaCategoryChecker(url) ? "IMAGE" : "VIDEO";

  const mediaObject: MediaGroup = {
    type: mediaType,
    media: [toMedia(url, index, userId)],
  };

  return mediaObject;
}

/**
 * A helper function to integrate react-beautiful-dnd
 *
 * @param list the list being reordered
 * @param startIndex the 'source' parameter from react-beautiful-dnd
 * @param endIndex the 'destination' parameter from react-beautiful-dnd
 */
export function reorder(list: any[], startIndex: number, endIndex: number): any[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
