import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Header, Grid, Loader } from "semantic-ui-react";

import {
  PlayerAccount,
  PricePlanTier,
  VideoProjectDetail,
  VideoProjectMessage,
  VideoProjectStatus,
} from "../../../interfaces";
import { SideBar, SocialNetworksSection, TitleSection, VideoProjectDetailComponent } from "../../../components";
import { AppContext } from "../../../providers";
import { VideoProjectServices } from "../../../services";

import { VideoProjectDetailsPageType, descriptors } from "./descriptors";

import { RowBasicPadding, SocialBasicRow, SocialGrid, SocialRowGrid, WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";
import { VideoProjectBubbleDetails } from "../../Editor/VideoProjectPage/components/BubbleDetails";
import { PSTimeZone, URLS, printDateTimePST, urlWithFormat, validatePendingAck } from "../../../utils";
import moment from "moment";
import { useSocialNetworks } from "../../../reducers";
import { get } from "lodash";
import { RepostModal } from "./components/RepostModal";

type Props = RouteComponentProps;

const VideoProjectDetailsPageFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { userContext } = React.useContext(AppContext);
  const { formatMessage } = useIntl();

  // Properties
  const [loading, setLoading] = React.useState<boolean>(false);
  const [videoProject, setVideoProject] = React.useState<VideoProjectDetail | undefined>(undefined);
  const [videoProjectMessages, setVideoProjectMessages] = React.useState<VideoProjectMessage[] | undefined>(undefined);
  const [needsAck, setNeedsAck] = React.useState<boolean>(false);
  const [postSnUrls, setPostSnUrls] = React.useState<{ [key: string]: string }>({});
  const [showPostModal, setShowPostModal] = React.useState<boolean>(false);

  const { sns } = useSocialNetworks();

  const coachId = get(userContext, "coach.id", undefined);
  const playerAccount: PlayerAccount = userContext.player
    ? { ...userContext.profile, ...userContext!!.account }
    : undefined;

  const isSCPlayer = React.useMemo(() => {
    const subscriptionType = get(userContext, "subscriptions[0].pricePlan.tier", "");
    const isSocialCoachPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;

    return !!(userContext.player && isSocialCoachPlus);
  }, [userContext]);

  const readyToPost = React.useMemo(() => {
    return isSCPlayer && videoProject && videoProject.status === VideoProjectStatus.READY_TO_POST;
  }, [videoProject, isSCPlayer]);

  React.useEffect(() => {
    let snUrls = {};

    videoProject?.post?.socialNetworks?.map(sn => {
      if (sn.externalPostUrl && sn.socialNetworkId !== "FB") {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: urlWithFormat(sn.externalPostUrl!.replace("[", "").replace("]", "")),
        };
      } else {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: sn.externalPostUrl!,
        };
      }
    });
    setPostSnUrls(snUrls);
  }, [videoProject?.post?.socialNetworks]);

  React.useEffect(() => {
    const ROLE = isSCPlayer ? "PLAYER" : "COACH";
    if (params["id"]) {
      setLoading(true);
      VideoProjectServices.get(params["id"])
        .then(vp => {
          setVideoProject(vp);
          setVideoProjectMessages(vp.messages);
          setNeedsAck(validatePendingAck(vp.ackList, ROLE));
        })
        .catch(() => {
          alert("Error getting video project info");
          if (isSCPlayer) {
            history.replace(URLS.player.library, { locationValues: { activeSection: "VIDEO_CATALYST" } });
          } else {
            history.goBack();
          }
        })
        .finally(() => {
          setLoading(false);
        });
      VideoProjectServices.acknowledgeMessage(params["id"]).catch(e => {
        console.error("acknowledgeMessage error", e);
      });
    }
  }, [params["id"]]);

  const refreshMessages = (): void => {
    VideoProjectServices.get(`${videoProject?.videoProjectId!!}`).then((r: VideoProjectDetail) => {
      setVideoProjectMessages(r.messages);
    });
  };

  /*
   * Post Info
   */
  const dateSection = (): JSX.Element => {
    moment.tz.setDefault(PSTimeZone);
    return (
      <SocialGrid>
        <SocialBasicRow>{videoProject!!.statusLabel}</SocialBasicRow>
        <SocialRowGrid className={"noPadding"}>{printDateTimePST(videoProject!!.statusDate)}</SocialRowGrid>
      </SocialGrid>
    );
  };

  const postSection = (): JSX.Element => {
    const postSnIds = videoProject?.post?.socialNetworks
      ?.filter(sn => sn.status === "POSTED")
      .map(sn => sn.socialNetworkId);
    return (
      <SocialGrid.Row className={"xbigPaddingTop"}>
        <SocialGrid.Column width={10}>
          <SocialGrid className={"autoPostSection"}>
            <RowBasicPadding>
              <Header as="h3" textAlign="left">
                {formatMessage({ ...descriptors[VideoProjectDetailsPageType.postSectionTitle] })}
              </Header>
            </RowBasicPadding>
            {videoProject && dateSection()}
            {postSnIds && postSnIds.length > 0 && (
              <RowBasicPadding className={"socialIconsSection"}>
                <SocialGrid className={"socialSection"} divided={"vertically"} columns={6}>
                  <SocialNetworksSection actives={postSnIds} links={postSnUrls} allSocialNetworks={sns} />
                </SocialGrid>
              </RowBasicPadding>
            )}
          </SocialGrid>
        </SocialGrid.Column>
      </SocialGrid.Row>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      {loading ? (
        <Loader active size="large" />
      ) : (
        <Grid columns={1} className={"contentSection"}>
          <TitleSection
            title={formatMessage({ ...descriptors[VideoProjectDetailsPageType.title] })}
            ready={true}
            disableButtons={false}
            titleSize={8}
            buttonsSize={8}
            showLinkOption={false}
            showNextOption={readyToPost}
            customNextLabel={formatMessage({ ...descriptors[VideoProjectDetailsPageType.postNowBtn] })}
            handleNextAction={() => setShowPostModal(true)}
          />
          <Grid.Row columns={1} className={"formSection leftPadding"}>
            <Grid className={"infoSection"}>
              <Grid.Column width={9} className={"formContainer"}>
                {videoProject && (
                  <VideoProjectDetailComponent coachId={coachId} player={playerAccount} videoProject={videoProject!!} />
                )}
              </Grid.Column>
              <Grid.Column width={6}>
                {videoProject && postSection()}
                {videoProjectMessages && (
                  <VideoProjectBubbleDetails
                    onSuccess={refreshMessages}
                    videoProjectId={videoProject?.videoProjectId!!}
                    ownerBoxTitle={formatMessage({ ...descriptors[VideoProjectDetailsPageType.ownerBoxTitle] })}
                    placeholder={formatMessage({ ...descriptors[VideoProjectDetailsPageType.inputPlaceHolder] })}
                    noteMessage={
                      !videoProject?.editable
                        ? formatMessage({ ...descriptors[VideoProjectDetailsPageType.disableNote] })
                        : undefined
                    }
                    messages={videoProjectMessages}
                    needsAck={needsAck}
                  />
                )}
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid>
      )}

      {showPostModal && (
        <RepostModal
          opened={showPostModal}
          videoProject={videoProject}
          closeHandler={() => {
            setShowPostModal(false);
          }}
        />
      )}
    </WhiteContainer>
  );
};

export const VideoProjectDetailsPage = withRouter(VideoProjectDetailsPageFC);
