import * as React from "react";
import axios from "axios";

import { Media } from "../../../../../interfaces";
import { printDatePST } from "../../../../../utils";

import { SubHeaderRowGrid } from "./styled";
import "./styles.scss";
import { Grid, Icon } from "semantic-ui-react";
import { MediaPlayer } from "../../../../MediaPlayer";

interface OwnProps {
  title: string;
  videoProjectId: number;
  type: "EDITED" | "ORIGINAL";
  videos: Media[];
  date: string;
}

type Props = OwnProps;

export const VideosSection: React.FC<Props> = ({ title, videoProjectId, type, videos, date }) => {
  const downloadFile = (filePath: string, fileName: string) => {
    axios.get(filePath, { responseType: "blob" }).then(response => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urlObject;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <div className={"attachVideosSection"}>
      <label>{title}</label>
      <SubHeaderRowGrid>{"Added " + printDatePST(date)}</SubHeaderRowGrid>

      <Grid>
        {videos &&
          videos.map((media, index) => {
            return (
              <Grid.Column
                key={`media_${type}_container_${index}`}
                className={type === "ORIGINAL" ? "videosContainer" : "videosContainer edited"}
              >
                <MediaPlayer media={media} />
                <div
                  className="downloadIcon"
                  onClick={() =>
                    downloadFile(media.uri!!, `${videoProjectId}_${type}_${media.category}_${index}.${media.extension}`)
                  }
                >
                  <Icon size={"large"} name="arrow alternate circle down outline" />
                </div>
              </Grid.Column>
            );
          })}
      </Grid>
    </div>
  );
};
