import { defineMessages } from "react-intl";

export enum VideoProjectPageType {
  title = "VideoProjectPageType.title",
  saveBtn = "VideoProjectPageType.saveBtn",
  cancelBtn = "VideoProjectPageType.Cancel",
  unknownError = "VideoProjectPageType.unknownError",
  savedMessage = "VideoProjectPageType.savedMessage",

  cancelAlertTitle = "VideoProjectPageType.cancelAlertTitle",
  yesBtn = "VideoProjectPageType.yesBtn",
  noBtn = "VideoProjectPageType.noBtn",
}

export const en = {
  [VideoProjectPageType.title]: "Video Catalyst Project",
  [VideoProjectPageType.saveBtn]: "Submit for Editing",
  [VideoProjectPageType.cancelBtn]: "Cancel",
  [VideoProjectPageType.cancelAlertTitle]: "Do you want to save this Video Project?",
  [VideoProjectPageType.yesBtn]: "Yes",
  [VideoProjectPageType.noBtn]: "No",

  [VideoProjectPageType.savedMessage]: "Video Project saved!",
  [VideoProjectPageType.unknownError]: "Unknown Error.",
};

export const descriptors = defineMessages({
  [VideoProjectPageType.title]: {
    id: VideoProjectPageType.title,
    defaultMessage: en[VideoProjectPageType.title],
    description: "-",
  },
  [VideoProjectPageType.cancelBtn]: {
    id: VideoProjectPageType.cancelBtn,
    defaultMessage: en[VideoProjectPageType.cancelBtn],
    description: "-",
  },
  [VideoProjectPageType.unknownError]: {
    id: VideoProjectPageType.unknownError,
    defaultMessage: en[VideoProjectPageType.unknownError],
    description: "-",
  },
  [VideoProjectPageType.saveBtn]: {
    id: VideoProjectPageType.saveBtn,
    defaultMessage: en[VideoProjectPageType.saveBtn],
    description: "-",
  },
  [VideoProjectPageType.cancelAlertTitle]: {
    id: VideoProjectPageType.cancelAlertTitle,
    defaultMessage: en[VideoProjectPageType.cancelAlertTitle],
    description: "-",
  },
  [VideoProjectPageType.yesBtn]: {
    id: VideoProjectPageType.yesBtn,
    defaultMessage: en[VideoProjectPageType.yesBtn],
    description: "-",
  },
  [VideoProjectPageType.noBtn]: {
    id: VideoProjectPageType.noBtn,
    defaultMessage: en[VideoProjectPageType.noBtn],
    description: "-",
  },
});
