import * as React from "react";
import { useIntl } from "react-intl";
import { DropdownItemProps, Grid, Header, Select, SemanticWIDTHS } from "semantic-ui-react";

import { ControlOption, SliderComponent } from "./components";
import { descriptors, RecordPageType } from "../../descriptors";
import "./styles.scss";
interface Props {
  onChangeSpeed(speed: number): void;
  onChangeTextSize(textSize: number): void;

  onChangeCamera(deviceId: string): void;
  onChangeMicrophone(deviceId: string): void;
}

const ControlSectionFC: React.FC<Props> = ({ onChangeSpeed, onChangeTextSize, onChangeCamera, onChangeMicrophone }) => {
  const { formatMessage } = useIntl();

  const [speed, setSpeed] = React.useState<number>(0.6);
  const [textSize, setTextSize] = React.useState<number>(22);

  const [selectedCamera, setCamera] = React.useState<string | undefined>(undefined);
  const [selectedMicrophone, setMicrophone] = React.useState<string | undefined>(undefined);

  const [cameraOptions, setCameraOptions] = React.useState<DropdownItemProps[]>([]);
  const [microphoneOptions, setMicrophoneOptions] = React.useState<DropdownItemProps[]>([]);

  React.useEffect(() => {
    onChangeSpeed(speed);
  }, [speed]);

  React.useEffect(() => {
    onChangeTextSize(textSize);
  }, [textSize]);

  React.useEffect(() => {
    if (selectedCamera !== undefined) {
      onChangeCamera(selectedCamera);
    }
  }, [selectedCamera]);

  React.useEffect(() => {
    if (selectedMicrophone !== undefined) {
      onChangeMicrophone(selectedMicrophone);
    }
  }, [selectedMicrophone]);

  const handleDevices = React.useCallback(
    (mediaDevices: MediaDeviceInfo[]) => {
      const devices = mediaDevices
        .filter(({ kind }) => kind === "videoinput")
        .map(element => {
          return {
            key: element.deviceId,
            text: element.label,
            value: element.deviceId,
          } as DropdownItemProps;
        });

      if (devices.length > 0) {
        setCamera(`${devices[0].value}`);
      }

      setCameraOptions(devices);

      const audios = mediaDevices
        .filter(({ kind }) => kind === "audioinput")
        .map(element => {
          return {
            key: element.deviceId,
            text: element.label,
            value: element.deviceId,
          } as DropdownItemProps;
        });

      setMicrophoneOptions(audios);
      if (audios.length > 0) {
        setMicrophone(`${audios[0].value}`);
      }
    },
    [setCameraOptions, setMicrophoneOptions]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <Grid.Row>
      <ControlOption title={formatMessage({ ...descriptors[RecordPageType.textSubtitle] })}>
        <SliderComponent min={10} max={36} name="textSize" onChange={setTextSize} step={2} value={textSize} />
      </ControlOption>

      <ControlOption title={formatMessage({ ...descriptors[RecordPageType.autoScrollSubtitle] })}>
        <SliderComponent min={0.1} max={1} name="speed" onChange={setSpeed} step={0.1} value={speed} />
      </ControlOption>

      {cameraOptions && cameraOptions.length > 0 && (
        <ControlOption title={formatMessage({ ...descriptors[RecordPageType.cameraSubtitle] })}>
          <Select
            placeholder={formatMessage({ ...descriptors[RecordPageType.cameraSubtitle] })}
            onChange={(_, data) => setCamera(`${data.value}`)}
            options={cameraOptions}
            defaultValue={selectedCamera && selectedCamera}
          />
        </ControlOption>
      )}
      {microphoneOptions && microphoneOptions.length > 0 && (
        <ControlOption title={formatMessage({ ...descriptors[RecordPageType.microphoneSubtitle] })}>
          <Select
            placeholder={formatMessage({ ...descriptors[RecordPageType.microphoneSubtitle] })}
            onChange={(_, data) => setMicrophone(`${data.value}`)}
            options={microphoneOptions}
            defaultValue={selectedMicrophone && selectedMicrophone}
          />
        </ControlOption>
      )}
    </Grid.Row>
  );
};

export const ControlSection = ControlSectionFC;
