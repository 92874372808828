import * as React from "react";
import { Header, Grid, Button } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { CoachSignUpStep, LoginStepServices } from "../../services";

import { TitleSectionType, descriptors } from "./descriptors";
import { isNil } from "lodash";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";

import "./styles.scss";
import { SCSubtitle } from "../../styling/baseStyle";

interface Props {
  title: string;
  subtitle?: string;
  currentStep?: CoachSignUpStep;
  disableButtons?: boolean;

  customNextLabel?: string;
  customLinkLabel?: string;

  showLinkOption?: boolean;
  showNextOption?: boolean;
  ready?: boolean;

  /**
   * Loading options
   */
  showLoading?: boolean;
  isLoading?: boolean;
  disableDuringLoading?: boolean;

  /**
   * Size of grid - title section
   */
  titleSize?: SemanticWIDTHS;
  /**
   * Size of grid - link section
   */
  buttonsSize?: SemanticWIDTHS;

  /**
   * Size of grid - link section
   */
  nextButtonSize?: SemanticWIDTHS;

  /**
   * Custom content for the link section
   */
  customLinkSection?: JSX.Element;

  /**
   * Custom content for the next section
   */
  customNextSection?: JSX.Element;

  showSeparator?: boolean;
  handleLoading?(loading: boolean): void;
  handleNextAction?(): void;
  handleSkipAction?(): void;
}

const TitleSectionFC: React.FC<Props> = ({
  title,
  subtitle,
  customNextLabel,
  customLinkLabel,
  disableButtons,
  currentStep,
  customLinkSection,
  customNextSection,
  showLoading = true,
  isLoading = false,
  disableDuringLoading = false,
  showLinkOption = false,
  showNextOption = true,
  ready = false,
  showSeparator = true,
  titleSize = 11,
  buttonsSize = 5,
  nextButtonSize = 8,
  handleLoading,
  handleNextAction,
  handleSkipAction,
}) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = React.useState<boolean>(false);
  const nextButtonLabel = customNextLabel
    ? customNextLabel
    : formatMessage({ ...descriptors[TitleSectionType[ready ? "saveBtn" : "saveNextBtn"]] });
  const skipButtonLabel = customLinkLabel
    ? customLinkLabel
    : formatMessage({ ...descriptors[TitleSectionType.skipBtn] });

  React.useEffect(() => {
    if (handleLoading) {
      handleLoading(loading);
    }
  }, [loading]);

  const onClickNext = async () => {
    setLoading(true);

    if (!ready && currentStep) {
      await LoginStepServices.sendStep(CoachSignUpStep[currentStep]).finally(() => {
        setLoading(false);
      });
    }

    if (handleNextAction) {
      handleNextAction();
    }

    setLoading(false);
  };

  const linkSection = (): JSX.Element => {
    const customSection = !isNil(customLinkSection);

    const content = (
      <Grid.Column floated="right" className="skip-link">
        <span onClick={handleSkipAction && handleSkipAction}>{skipButtonLabel}</span>
      </Grid.Column>
    );

    return customSection ? customLinkSection! : content;
  };

  const nextSection = (): JSX.Element => {
    const customSection = !isNil(customNextSection);

    const content = (
      <Grid.Column floated="right" width={nextButtonSize}>
        <Button
          floated="right"
          disabled={disableButtons || (disableDuringLoading && isLoading)}
          fluid
          onClick={onClickNext}
          className={"primary rounded"}
          data-elm-id={`titleSection${nextButtonLabel.replace(" ", "")}Btn`}
          loading={showLoading && (loading || isLoading)}
        >
          {nextButtonLabel}
        </Button>
      </Grid.Column>
    );

    return customSection ? customNextSection! : content;
  };

  return (
    <Grid columns={1} className={"titleSectionContainer"}>
      <Grid.Row columns={2}>
        <Grid.Column width={titleSize}>
          <Header as="h2">{title}</Header>
        </Grid.Column>
        <Grid.Column width={buttonsSize}>
          <Grid>
            {showLinkOption && linkSection()}
            {showNextOption && nextSection()}
          </Grid>
        </Grid.Column>
      </Grid.Row>

      <Grid.Column>{showSeparator && <div className={"line"} />}</Grid.Column>
      {subtitle && (
        <Grid.Column className={"basicPadding"}>
          <SCSubtitle className={"mediumLeftMargin"}> {subtitle} </SCSubtitle>
        </Grid.Column>
      )}
    </Grid>
  );
};

export const TitleSection = TitleSectionFC;
