import * as React from "react";
import { UploadTask } from "firebase/storage";

import { getDownloadURLFirebase, saveElementToFirebase, saveElementToFirebaseCancellable } from "../../utils/firebase";
import { Media, UploadProgressMap, FirebaseConfig, FileErrorDescription } from "../../interfaces";
import {
  createVideoThumbnailBlob,
  dataURLtoBlob,
  checkFileDuration2,
  getVideoDimensions,
  mediaVideoProjectFirebaseUrl,
  validAspectRatio,
  validDimensions,
  getValidFiles,
  reorder,
} from "../../utils";

import { VideoProjectMediaContainer } from "../VideoProjectMediaContainer";

import "./styles.scss";
import { UploadedVideoFilesManager } from "./styles";

const MAXREELSDURATION = 900;
const MINREELSDURATION = 3;
const MAXEDITEDMEDIA = 1;
const MAXMEDIA = 10;

interface MultiVideoUploaderProps {
  handleMediaUpdated: (newMedia: Media[]) => void;
  handleAttachedFileUploadStarted: () => void;
  handleAttachedFileUploadFinished: () => void;

  firebase: FirebaseConfig;
  coachId: string;
  editedFile?: boolean;
  originalMedia: Media[];
}

/*
 *
 * This Multi-Image uploader is designed to allow users to upload variable image and video media
 * types and reorder them using the DraggableMediaContainer.
 *
 */

const MultiMediaUploaderFC: React.FC<MultiVideoUploaderProps> = ({
  handleMediaUpdated,
  handleAttachedFileUploadStarted,
  handleAttachedFileUploadFinished,
  firebase: firebaseConfig,
  coachId,
  originalMedia,
  editedFile = false,
}) => {
  const [mediaLoaded, setMediaLoaded] = React.useState<boolean>(false);
  const [numberOfFiles, setNumberOfFiles] = React.useState<number>(0);

  // const uploadProgress = React.useRef<UploadProgressMap>({});
  const [uploadProgress, setUploadProgress] = React.useState<UploadProgressMap>({});
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);
  const media = React.useRef<Media[]>(originalMedia || []);

  React.useEffect(() => {
    if (media && media.current && media.current.length > 0) {
      setMediaLoaded(true);
      const uploadProgressTemp: UploadProgressMap = {};
      media.current.forEach(item => {
        uploadProgressTemp[item.id] = { progress: 1, task: undefined };
      });

      setUploadProgress(uploadProgressTemp);
    }
  }, []);

  /**
   * handles browse button clicks
   */
  const handleBrowseButtonClicked = (): void => {
    const maxMediaFile = editedFile ? MAXEDITEDMEDIA : MAXMEDIA;
    if (media && media.current && media.current.length <= maxMediaFile) {
      hiddenFileInput.current?.click();
    } else if (editedFile) {
      hiddenFileInput.current?.click();
    } else {
      alert(`We're only allowed to send ${maxMediaFile} media items at a time to social media. Sorry about that!`);
    }
  };

  /**
   * Occurs directly after the user selects files to upload from their local device.
   */
  const handleFilesSelected = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputFiles = e.target.files || new FileList();
    let validFiles = getValidFiles(Array.from(inputFiles));

    // no files provided
    if (validFiles.length === 0) {
      return;
    }

    const maxMediaFile = editedFile ? MAXEDITEDMEDIA : MAXMEDIA;
    const diff = validFiles.length + media.current.length - maxMediaFile;
    if (!editedFile && diff > 0) {
      alert(`We're only allowed to send ${maxMediaFile} media items at a time to social media. Sorry about that!`);
      const validRange = maxMediaFile - media.current.length;
      validFiles = validFiles.splice(0, validRange);
    } else if (editedFile) {
      media.current = [];
      //setMediaLoaded(false)
    }

    // all file types were valid
    beginFirebaseUpload(validFiles);
  };

  /**
   *  Converts File objects to image data for the component to use.
   */
  function beginFirebaseUpload(files: File[]): void {
    // get the next available id
    // const existingMedia = media;
    const ids = media.current.map(item => item.id);
    let nextId = ids.length > 0 ? Math.max(...ids) + 1 : 1;
    const sortOrders = media.current.map(item => item.sortOrder);
    let nextSortOrder = sortOrders.length > 0 ? Math.max(...sortOrders) + 1 : 1;

    setMediaLoaded(true);
    setNumberOfFiles(files.length);

    const uploadPromises: UploadTask[] = [];
    // pass each new media object to the parent component
    files.forEach(file => {
      handleAttachedFileUploadStarted();
      // pass placeholder element to parent component via handleMediaUpdated handler
      const mediaCategory = file.type.includes("image/") ? "IMAGE" : "VIDEO";
      const timestamp = new Date().getTime();
      const fileName = file.name.split(".")[0] + timestamp;

      if (editedFile) {
        checkFileDuration2(
          file,
          (errors?: FileErrorDescription) => {
            const mediaObject: Media = {
              id: nextId++,
              sortOrder: nextSortOrder++,
              category: mediaCategory,
              uri: "",
              type: file.type,
              extension: file.type.split("/")[1],
              fileName,
              errors,
            };

            // save file to firebase asynchronously
            const uploadTask = saveElementToFirebaseCancellable(
              firebaseConfig,
              mediaVideoProjectFirebaseUrl(coachId),
              file,
              fileName
            );
            media.current = [...media.current, mediaObject];
            handleMediaUpdated(media.current);

            uploadProgress[mediaObject!.id!] = {
              progress: 0,
              task: uploadTask,
            };

            // set Firebase callback functions
            uploadTask.on(
              "state_changed",
              snapshot => {
                handleFirebaseUploadProgress(snapshot, mediaObject!.id!);
              }, // on progress update
              null, // TODO: on upload failure
              () => handleFirebaseUploadComplete(file, mediaObject) // on upload complete
            );

            uploadPromises.push(uploadTask);
          },
          MAXREELSDURATION,
          MINREELSDURATION,
          true
        );
      } else {
        const errors: FileErrorDescription = {
          wrongBitrate: false,
          wrongSize: false,
          wrongLength: false,
        };

        const mediaObject: Media = {
          id: nextId++,
          sortOrder: nextSortOrder++,
          category: mediaCategory,
          uri: "",
          type: file.type,
          extension: file.type.split("/")[1],
          fileName,
          errors,
        };

        media.current = [...media.current, mediaObject];
        handleMediaUpdated(media.current);

        // save file to firebase asynchronously
        const uploadTask = saveElementToFirebaseCancellable(
          firebaseConfig,
          mediaVideoProjectFirebaseUrl(`${coachId}`),
          file,
          fileName
        );
        uploadProgress[mediaObject!.id!] = { progress: 0, task: uploadTask };

        // set Firebase callback functions
        uploadTask.on(
          "state_changed",
          snapshot => {
            handleFirebaseUploadProgress(snapshot, mediaObject!.id!);
          }, // on progress update
          null, // TODO: on upload failure
          () => handleFirebaseUploadComplete(file, mediaObject) // on upload complete
        );

        uploadPromises.push(uploadTask);
      }
    });

    Promise.all(uploadPromises).then(values => {
      handleAttachedFileUploadFinished();
    });
  }

  const uploadThumbnailToFirebase = (
    uri: string,
    mediaObject: Media,
    isValidAspectRatio: boolean,
    isValidDimensions: boolean
  ): Promise<Media> => {
    return new Promise<Media>((resolve, reject) => {
      createVideoThumbnailBlob(uri)
        .then(videoThumbnailUri => {
          const blob = dataURLtoBlob(videoThumbnailUri);

          saveElementToFirebase(
            firebaseConfig,
            "thumbnail/" + mediaObject.fileName,
            blob,
            "thumbnail_" + mediaObject.fileName
          )
            .then(urlThumba => {
              resolve({
                ...mediaObject,
                uri,
                downloadLink: uri,
                thumbnailUri: urlThumba,
                validAspectRatio: isValidAspectRatio,
                validDimension: isValidDimensions,
              });
            })
            .catch(e => {
              resolve({
                ...mediaObject,
                uri,
                downloadLink: uri,
                validAspectRatio: isValidAspectRatio,
              });
            });
        })
        .catch(() => {
          resolve({
            ...mediaObject,
            uri,
            downloadLink: uri,
            validAspectRatio: isValidAspectRatio,
          });
        });
    });
  };

  const handleFirebaseUploadProgress = (snapshot: any, mediaId: number): void => {
    const uploadProgressTemp = snapshot.bytesTransferred / snapshot.totalBytes;

    setUploadProgress(current => {
      return {
        ...current,
        [mediaId]: {
          ...uploadProgressTemp[mediaId],
          progress: uploadProgressTemp,
        },
      };
    });
  };

  function handleFirebaseUploadComplete(file: File, mediaObject: Media): void {
    getDownloadURLFirebase(firebaseConfig, mediaVideoProjectFirebaseUrl(coachId), file, mediaObject.fileName!).then(
      uri => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = uri || "";

        if (mediaObject.category === "VIDEO") {
          const urlPromise = getVideoDimensions(uri);
          urlPromise
            .then(dimension => {
              uploadThumbnailToFirebase(
                uri,
                mediaObject,
                validAspectRatio(dimension.height, dimension.width, editedFile),
                validDimensions(dimension.height, dimension.width, editedFile)
              ).then(finalMedia => handleUpdateMediaObject(finalMedia));
            })
            .catch(() => {
              uploadThumbnailToFirebase(uri, mediaObject, true, true).then(finalMedia => {
                handleUpdateMediaObject(finalMedia);
              });
            });
        } else {
          img.onload = () => {
            handleUpdateMediaObject({
              ...mediaObject,
              uri,
              downloadLink: uri,
              validAspectRatio: true,
              validDimension: true,
              imageFile: file,
            });
          };
        }
      }
    );
  }

  function handleUpdateMediaObject(mediaObject: Media): void {
    const otherMediaObjects = media.current.filter(item => item.id !== mediaObject.id);
    const newMedia = [...otherMediaObjects, mediaObject];

    handleMediaUpdated(newMedia);
    media.current = newMedia;
  }

  /**
   *  Updates media state when user deletes a media object.
   */
  const handleDeletedMedia = (id: number): void => {
    uploadProgress[id]?.task?.cancel();
    const newMedia = media.current.filter(mediaItem => mediaItem.id !== id);
    media.current = newMedia;
    handleMediaUpdated(newMedia);

    // reset mediaLoaded if there are no more media items
    if (newMedia.length === 0 && numberOfFiles - 1 === 0) {
      setMediaLoaded(false);
      setNumberOfFiles(newMedia.length);
      hiddenFileInput.current!.value = "";
    } else {
      setNumberOfFiles(numberOfFiles - 1);
    }
  };

  /**
   *  Updates media state when user update a media object.
   */
  const handleUpdateMedia = (id: number, uri: string): void => {
    const newMedia = media.current.map(mediaItem => {
      if (mediaItem.id === id) {
        return {
          ...mediaItem,
          uri,
          validAspectRatio: true,
          downloadLink: uri,
        };
      }
      return mediaItem;
    });

    media.current = newMedia;
    handleMediaUpdated(newMedia);
  };

  /**
   * Passes the drag n drop result to the parent component
   * @param result the result sent to us by react-beautiful-dnd DragDropContext object when the user drops an item
   */
  const onDragEnd = (result: any): void => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const rowSize = 5;
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    const realDestinationIndex = dInd * rowSize + result.destination.index;
    const realSourceIndex = sInd * rowSize + result.source.index;
    const newMedia = reorder(media.current, realSourceIndex, realDestinationIndex);
    media.current = newMedia;
    handleMediaUpdated(newMedia);
  };

  const filesLoader = media && media.current ? numberOfFiles > media.current.length : 0;
  return (
    <div className="multi-video-uploader-wrapper">
      <form className="multi-video-uploader-input-form">
        <input
          className="custom-file-input"
          type="file"
          multiple
          accept={"image/*, video/*"}
          onChange={handleFilesSelected}
          ref={hiddenFileInput}
        />
        <UploadedVideoFilesManager
          medialoaded={mediaLoaded ? 1 : 0}
          onClick={() => {
            if (!mediaLoaded) {
              handleBrowseButtonClicked();
            }
          }}
          className="uploaded-files-manager"
        >
          {
            /* Pass selected media items to the interactive media manager; otherwise, show prompt */
            mediaLoaded && (
              <div className={filesLoader ? "loader" : ""}>
                <VideoProjectMediaContainer
                  media={originalMedia}
                  uploadProgress={uploadProgress}
                  handleClose={handleDeletedMedia}
                  handleUpdate={handleUpdateMedia}
                  onDragEnd={onDragEnd}
                />
              </div>
            )
          }
        </UploadedVideoFilesManager>
        <div className="browse-button" onClick={handleBrowseButtonClicked}>
          {editedFile && originalMedia.length > 0 ? "Replace" : editedFile ? "Upload" : "Add File"}
        </div>
      </form>
    </div>
  );
};

export const MultiMediaUploaderComponent = MultiMediaUploaderFC;
