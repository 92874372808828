import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Header, Grid, Button } from "semantic-ui-react";

import { SocialNetworkIconButton } from "../../../../../components/SocialNetworkIconButton";
import { SocialNetworksId, VideoScriptDetails } from "../../../../../interfaces";
import { VideoScriptsServices } from "../../../../../services";
import { RowBasicPadding, SocialGrid } from "../../../../../styling/baseStyle";
import { printOnlyDate, urlWithFormat } from "../../../../../utils";

import { PostVideoSection } from "../PostVideoSection";

import { descriptors, ScriptDetailsType } from "./descriptors";
import "./styles.scss";

const sns: SocialNetworksId[] = ["FB", "LI", "IG", "IG_STORY", "TW", "TT"];

interface OwnProps {
  videoScriptId: number;
}

const dataElmId = "ScriptDetails";
type Props = OwnProps;

/**
 * Represents a Video Script details
 */
const IntegratedVideoScriptDetails: React.FC<Props> = ({ videoScriptId }) => {
  const [index, setIndex] = React.useState<number>(0);
  const [scriptDetails, setScriptDetails] = React.useState<VideoScriptDetails | undefined>(undefined);
  const [postSnUrls, setPostSnUrls] = React.useState<{ [key: string]: string }>({});

  React.useEffect(() => {
    const getScriptDetails = async (scriptId: number) => {
      VideoScriptsServices.getDetails(scriptId).then(setScriptDetails);
    };

    getScriptDetails(videoScriptId);
  }, [videoScriptId]);

  const videoProject =
    scriptDetails && scriptDetails.latestVideoProjects ? scriptDetails?.latestVideoProjects[index] : undefined;

  const showPostControllers =
    scriptDetails && scriptDetails.latestVideoProjects && scriptDetails.latestVideoProjects.length > 1;

  React.useEffect(() => {
    let snUrls = {};

    videoProject?.post?.socialNetworks?.map(sn => {
      if (sn.externalPostUrl && sn.socialNetworkId !== "FB") {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: urlWithFormat(sn.externalPostUrl!.replace("[", "").replace("]", "")),
        };
      } else {
        snUrls = {
          ...snUrls,
          [sn.socialNetworkId]: sn.externalPostUrl!,
        };
      }
    });
    setPostSnUrls(snUrls);
  }, [scriptDetails?.latestVideoProjects[index]]);

  const titleSection = (
    descriptiorName: string,
    customClass: string = "noMargin noPadding",
    as: string = "h3"
  ): JSX.Element => {
    return (
      <Header as={as} textAlign="left" className={customClass}>
        <FormattedMessage {...descriptors[ScriptDetailsType[descriptiorName]]} />
      </Header>
    );
  };

  const headerElement = (descriptiorName: string, value?: string | number, as: string = "h5"): JSX.Element => {
    return (
      <Grid.Row className="noMargin noPadding">
        <Header as={as} textAlign="left" className="noMargin noPadding">
          <FormattedMessage {...descriptors[ScriptDetailsType[descriptiorName]]} />
          {value && value}
        </Header>
      </Grid.Row>
    );
  };

  const pElement = (descriptiorName: string, value?: string | number): JSX.Element => {
    return (
      <Grid.Row className="noMargin noPadding">
        <p>
          <FormattedMessage {...descriptors[ScriptDetailsType[descriptiorName]]} />
          {value}
        </p>
      </Grid.Row>
    );
  };

  const infoSection = (): JSX.Element => {
    return (
      <>
        {titleSection("title")}

        <Grid.Row className="noMargin noPadding">
          <Header as="h5" textAlign="left" className="noMargin noPadding">
            {scriptDetails ? scriptDetails?.ownerName : ""}
          </Header>
        </Grid.Row>

        {pElement("createdLabel", printOnlyDate(scriptDetails?.createdDate))}
        {pElement("lastEditedLabel", printOnlyDate(scriptDetails?.updatedDate))}
      </>
    );
  };

  const postsSection = (): JSX.Element => {
    return (
      <Grid.Row className={"videoProjectBubble"}>
        <Grid>
          {titleSection("posts")}
          <Grid.Row className="postButtonsSection">
            <Grid columns={showPostControllers ? 3 : 1}>
              {showPostControllers && (
                <Grid.Column>
                  <Button
                    className={index === 0 ? "btnClickable disabled" : "btnClickable"}
                    onClick={() => setIndex(index - 1)}
                  >
                    Prev
                  </Button>
                </Grid.Column>
              )}
              <Grid.Column className="postCounterColumn">
                <p>
                  {`${index + 1} of ${
                    scriptDetails && scriptDetails?.latestVideoProjects ? scriptDetails?.latestVideoProjects.length : 0
                  }`}
                </p>
              </Grid.Column>
              {showPostControllers && (
                <Grid.Column>
                  <Button
                    className={
                      index === scriptDetails?.latestVideoProjects.length - 1 ? "btnClickable disabled" : "btnClickable"
                    }
                    onClick={() => setIndex(index + 1)}
                  >
                    Next
                  </Button>
                </Grid.Column>
              )}
            </Grid>
          </Grid.Row>

          {videoProject && (
            <Grid.Row className="postCardGrid">
              <div>
                <PostVideoSection videos={videoProject.editedMedia?.media!!} playerInfo={videoProject.playerAccount} />
                <SocialGrid className={"socialSection"}>
                  <RowBasicPadding className={"socialIconsSection"}>
                    {sns.map((sn, index) => {
                      const active =
                        videoProject.post?.socialNetworks.filter(s => s.socialNetworkId === sn).length || 0;
                      return (
                        <SocialNetworkIconButton
                          disabled={false}
                          withPadding={false}
                          size={"mini"}
                          key={"script_sn_icon_" + index}
                          active={active > 0}
                          networkId={sn}
                          link={postSnUrls ? postSnUrls[sn] : undefined}
                        />
                      );
                    })}
                  </RowBasicPadding>
                </SocialGrid>
              </div>
            </Grid.Row>
          )}
        </Grid>
      </Grid.Row>
    );
  };
  return (
    <Grid className={"scriptDetailsContainer"}>
      {/* {infoSection()} */}
      {scriptDetails && scriptDetails?.latestVideoProjects.length > 0 && postsSection()}
    </Grid>
  );
};

export const ScriptDetails = IntegratedVideoScriptDetails;
