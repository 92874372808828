import styled from "styled-components";
import { Card, Grid } from "semantic-ui-react";
import { theme } from "../../../../../styling/theme";
import { Svg } from "../../../../../assets";

const defaultImage = Svg.PromptPlaceholder;

export const SubHeaderRowGrid = styled(Grid.Row)({
  paddingBottom: "10px !important",
  paddingLeft: "0px",
  color: `${theme.subtitleColor}`,
});

const thumbnailSize = (thumbnail?: string): string => {
  if (thumbnail) {
    return `url("${thumbnail}") no-repeat center top`;
  }

  return `url(${defaultImage}) no-repeat center center`;
};
export const VideoProjectCardStyle = styled(Card)({
  background: `${theme.lightBackgroundColor} !important`,
  borderRadius: "6.3px !important",
  border: `1px solid ${theme.backgroundColor} !important`,
  boxShadow: `none !important`,
  width: "150px !important",
  height: "160px !important",
  margin: " 0 12px !important",
  display: "inline-block",
});
export const VideoProjectHeader = styled(VideoProjectCardStyle.Header)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});
export const ThumbnailPreview = styled(Grid.Column)`
  width: 120px;
  height: 200px;
  margin: 0px;
  margin-right: 30px;
  background: ${(props: any) => thumbnailSize(props.thumbnailurl)};
  background-size: ${(props: any) => (props.thumbnailurl ? "cover" : "inherit")};
  background-color: #eaeaf0;
  border-radius: 10px;
`;

export const CaptionSection = styled.div`
  background-color: white;
  border: 0.9px solid ${theme.boxLightBorder};
  font-size: 1.143em;
  font-family: "Open Sans", sans-serif;
  min-height: 200px;
  padding: 10px;
  white-space: pre-wrap;
`;
