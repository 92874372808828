import * as React from "react";
import { Form, Loader, Message } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

import { BasicFormProps } from "../../../interfaces";
import { Rules } from "../../../utils";

import "../../../styling/datepicker.scss";
import { SCFormField } from "../../../styling/baseStyle";
import "./styles.scss";

interface OwnProps extends BasicFormProps {
  /**
   * Handle datePickers changes
   */
  handleSetFieldValue?: (field: string, value: any) => void;
  disabled?: boolean;
}

type Props = OwnProps;
const dataElmId = "videoScriptDetail";
/**
 * Represents Video Script content.
 */
const VideoScriptDetailFC: React.FC<Props> = ({
  handleSetFieldValue,
  isSubmitting = false,
  messages,
  values,
  disabled = false,
}) => {
  const errors = React.useMemo(() => {
    return messages && messages.error && messages.error.length > 0;
  }, [messages]);

  const titleField = () => {
    return (
      <Form.Input
        key={"groupTitleInput"}
        label={"Title"}
        fluid
        id={"title"}
        type={"text"}
        name={"title"}
        placeholder={"Title"}
        onChange={(e, data) => {
          if (e.target.value.length < Rules.MAX_TITLE) {
            handleSetFieldValue!("title", e.target.value);
          }
        }}
        value={values.title}
      />
    );
  };

  const scriptField = () => {
    return (
      <SCFormField>
        <div>
          <label>Script</label>
        </div>
        <TextareaAutosize
          className={"scriptContent"}
          id="content"
          name="content"
          rows={3}
          value={values.content}
          onChange={data => {
            handleSetFieldValue!("content", data.currentTarget.value);
          }}
        />
      </SCFormField>
    );
  };

  return (
    <Form
      size="large"
      error={errors}
      className={disabled ? "videoScriptDetailsContainer disabledForm" : "videoScriptDetailsContainer"}
    >
      {isSubmitting && <Loader active size="large" />}

      <div key={"videoScriptFormDiv"} className={"whiteFormDiv"}>
        {messages && messages.error && messages.error.length > 0 && <Message error list={messages && messages.error} />}

        {titleField()}
        {scriptField()}
      </div>
    </Form>
  );
};

export const VideoScriptDetail = VideoScriptDetailFC;
