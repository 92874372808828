import * as React from "react";
import { get } from "lodash";

import * as DTO from "../../../../../interfaces";
import { mediaElement } from "../../../../../utils";
import {
  UploadMultipleElements,
  PromptPreviewCell,
  BulkAllSectionCell,
} from "../../../../../components";
import { CategoriesServices } from "../../../../../services";
import { AppContext } from "../../../../../providers";

import {
  ModalSection,
  ModalCentered,
  DescriptionSection,
  ModalHeader,
  ModalDescription,
  BulkContainer,
  SocialButton,
} from "./styled";
import { getDownloadURL } from "firebase/storage";

interface Props {
  trigger: JSX.Element;

  /**
   * Called when all prompts images/videos have been uploaded
   */
  onUploaded(prompts: { [key: string]: DTO.PromptPreview }): void;
  /**
   * Called when all prompts images/videos are uploading
   */
  submittingFiles(prompts: { [key: string]: DTO.PromptPreview }): void;

  modalOpened(refresh: boolean): void;
}

const UploadModalFC: React.FC<Props> = ({
  trigger,
  onUploaded,
  modalOpened,
  submittingFiles,
}) => {
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  // const [selectAll, setSelectAll] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [promptsBulkDic, setPromptsBulkDic] = React.useState<{
    [key: string]: DTO.PromptPreview;
  }>({});
  const [coachCategories, setCoachCategories] = React.useState<DTO.Category[]>(
    []
  );

  const [someSelected, setSomeSelected] = React.useState<boolean>(false);
  const [allSelected, setAllSelected] = React.useState<boolean>(false);
  const [submitEnable, setSubmitEnable] = React.useState<boolean>(false);

  const [nextMediaId, setNextMediaId] = React.useState<number>(1);

  const refreshPromptStatus = (selected: boolean): void => {
    setPromptsBulkDic((prompts) => {
      let updated = prompts;
      Object.values(prompts).map((prompt) => {
        updated = {
          ...updated,
          [prompt.uid]: {
            ...prompt,
            selected,
          },
        };
      });
      return updated;
    });
  };

  React.useEffect(() => {
    const selectedS = Object.values(promptsBulkDic).map(
      (prompt) => prompt.selected || false
    );
    const errorStatus = Object.values(promptsBulkDic).map(
      (prompt) => !prompt.withError || false
    );

    const some = selectedS.reduce(
      (accumulator, selected) => selected || false || accumulator,
      false
    );
    const all = selectedS.reduce(
      (accumulator, selected) => (selected || false) && accumulator,
      true
    );
    const allPromptsOk = errorStatus.reduce(
      (accumulator, ready) => ready && accumulator,
      true
    );

    setSubmitEnable(allPromptsOk);
    setSomeSelected(some);
    setAllSelected(all);
  }, [promptsBulkDic]);

  React.useEffect(() => {
    if (loading) {
      submittingFiles(promptsBulkDic);
    }
  }, [promptsBulkDic]);

  React.useEffect(() => {
    const getCategories = async () => {
      const result = await CategoriesServices.get(coachId);
      setCoachCategories(() => result);
    };

    getCategories();
  }, [coachId]);

  const promptFromFile = (file: DTO.SocialFiles): DTO.PromptPreview => {
    const mediaType = file.type.includes("video") ? "VIDEO" : "IMAGE";
    const message = "This prompt was created in a bulk upload on " + new Date();

    const post: DTO.MediaGroup = {
      media: [mediaElement("", file.type, mediaType, nextMediaId)],
      mediaGroupId: undefined,
      type: mediaType,
    };

    file.uploadTask
      ?.then(() => {
        getDownloadURL(file.uploadTask!.snapshot.ref)
          .then((url) => {
            const postWithUrl: DTO.MediaGroup = {
              media: [mediaElement(url, file.type, mediaType, nextMediaId)],
              mediaGroupId: undefined,
              type: mediaType,
            };
            setPromptsBulkDic((prompts) => {
              if (prompts[file.uid]) {
                return {
                  ...prompts,
                  [file.uid]: {
                    ...prompts[file.uid],
                    loadStatus: "SUBMITTED",
                    post: postWithUrl,
                  },
                };
              }
              return {
                ...prompts,
              };
            });
          })
          .catch((e) => {
            setPromptsBulkDic((prompts) => {
              if (prompts[file.uid]) {
                return {
                  ...prompts,
                  [file.uid]: {
                    ...prompts[file.uid],
                    loadStatus: "FAILED",
                  },
                };
              }
              return {
                ...prompts,
              };
            });
          });
      })
      .catch((e) => {
        setPromptsBulkDic((prompts) => {
          if (prompts[file.uid]) {
            return {
              ...prompts,
              [file.uid]: {
                ...prompts[file.uid],
                loadStatus: "FAILED",
              },
            };
          }
          return {
            ...prompts,
          };
        });
      });

    setNextMediaId(nextMediaId + 1);

    const newPrompt: DTO.PromptPreview = {
      title: file.name,
      deliveryMode: "LIBRARY",
      richMessage: message,
      message,
      status: "DRAFT",
      post,
      loading: true,
      uid: file.uid,
      uploadTask: file.uploadTask,
      thumbnail: file.thumbnail,
    };

    return newPrompt;
  };

  const prepareToSubmit = () => {
    setLoading(true);
    setOpen(false);
    submittingFiles(promptsBulkDic);
    onUploaded(promptsBulkDic);
  };

  const onDeletePrompt = (uid: string): void => {
    setPromptsBulkDic((prompts) => {
      delete prompts[uid];
      return { ...prompts };
    });
  };

  const onChangePrompt = (updatedPrompt: DTO.PromptPreview): void => {
    setPromptsBulkDic((prompts) => {
      return {
        ...prompts,
        [updatedPrompt.uid]: updatedPrompt,
      };
    });
  };

  const onChangeCategoryForSelected = (selectedCategory: string) => {
    setPromptsBulkDic((prompts) => {
      let updatedPrompts = prompts;
      Object.values(prompts).map((p) => {
        updatedPrompts = {
          ...updatedPrompts,
          [p.uid]: {
            ...p,
            categoryIds: p.selected ? [selectedCategory] : p.categoryIds,
          },
        };
      });
      return updatedPrompts;
    });
  };

  return (
    <ModalCentered
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
        modalOpened(true);
        setLoading(false);
        setPromptsBulkDic({});
      }}
      closeIcon
      dimmer={"blurring"}
      key={"uploadModalBulkModal"}
      size={"small"}
      trigger={trigger}
    >
      <ModalSection>
        <ModalHeader>Upload Files</ModalHeader>
        <ModalDescription centered={"true"}>
          <DescriptionSection>
            <UploadMultipleElements
              fileName={`promptsInBulk/${coachId}`}
              title={"Or Browse"}
              onChangeImage={(file) => {
                setPromptsBulkDic((prompts) => {
                  return {
                    ...prompts,
                    [file.uid]: promptFromFile(file),
                  };
                });
              }}
            />
          </DescriptionSection>
          {Object.keys(promptsBulkDic).length > 0 && (
            <BulkContainer columns={3}>
              <BulkAllSectionCell
                categories={coachCategories}
                onSelectAll={refreshPromptStatus}
                deleteAll={() => setPromptsBulkDic({})}
                someCategoriesSelected={someSelected}
                allSelected={allSelected}
                onChange={onChangeCategoryForSelected}
              />
              {Object.values(promptsBulkDic).map((prompt) => {
                return (
                  <PromptPreviewCell
                    key={"bulk" + prompt.uid}
                    categories={coachCategories}
                    prompt={prompt}
                    onDelete={onDeletePrompt}
                    onChange={onChangePrompt}
                  />
                );
              })}

              <SocialButton
                data-elm-id={"uploadModalUploadFilesBtn"}
                disabled={!submitEnable}
                loading={loading}
                className={"primary"}
                onClick={prepareToSubmit}
              >
                Upload Files
              </SocialButton>
            </BulkContainer>
          )}
        </ModalDescription>
      </ModalSection>
    </ModalCentered>
  );
};

export const UploadModal = React.memo(UploadModalFC);
