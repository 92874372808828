import * as React from "react";
import { Modal, Header } from "semantic-ui-react";

import { PlayerPostSuccess, PostModalForm } from "../../../../../../../components";
import { PostPlayerRequest, Prompt, ReadyToPostContentType, SendModeType } from "../../../../../../../interfaces";
import { AppContext } from "../../../../../../../providers";
import { HashTagsServices, PromptServices, VideoProjectServices } from "../../../../../../../services";
import { getSocialNetworkMessages } from "../../../../../../../utils";

import { modalReducer } from "./modalReducer";
import "./styles.scss";
import { usePlayerSocialNetworks } from "../../../../../../../reducers";
interface OwnProps {
  opened: boolean;
  contentId: number;
  contentType: ReadyToPostContentType;
  thumbnail: string;
  closeHandler: () => void;
}

type Props = OwnProps;

/**
 * Repost Flow Modal Components
 */
const RepostModalFC: React.FC<Props> = ({ opened, contentId, contentType, thumbnail, closeHandler }) => {
  const { userContext } = React.useContext(AppContext);
  const { playerSn } = usePlayerSocialNetworks();

  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false });
  const [showSuccessPage, setShowSuccessPage] = React.useState<boolean>(false);
  const [postPlayerRequest, setPostPlayerRequest] = React.useState<PostPlayerRequest | undefined>(undefined);

  const getMessage = async (prompt: Prompt): Promise<string> => {
    return new Promise(resolve => {
      const playerId = userContext!!.player!!.id;

      HashTagsServices.get(playerId).then(response => {
        let message = prompt!.message || prompt!.richMessage || "";

        // Include prompt hashtags
        message = prompt.hashtags ? message + `\n${prompt.hashtags}` : message;

        // Include player hashtags
        const hashtags = response.map(hashtag => hashtag.content);
        const hashtagsToInclude = hashtags?.filter(hashtag => !message.includes(hashtag));
        if (
          hashtagsToInclude &&
          hashtagsToInclude.length > 0 &&
          userContext?.player!!.addHashTagAutomatically &&
          prompt?.allowHashtags
        ) {
          message = message + `\n#${hashtagsToInclude.join(" #")}`;
        }

        // Include compliance disclaimer
        if (userContext?.profile?.complianceDisclaimer && prompt?.allowComplianceDisclaimer) {
          message = message + userContext?.profile?.complianceDisclaimer;
        }

        resolve(message);
      });
    });
  };

  React.useEffect(() => {
    if (contentType === "PROMPT") {
      dispatch({ type: "loading" });
      PromptServices.get(contentId)
        .then(async promptResponse => {
          await getMessage(promptResponse).then(messageToPost => {
            const request: PostPlayerRequest = {
              socialNetworkMessages: getSocialNetworkMessages(playerSn),
              messageToPost: `${messageToPost}`,
              playerId: userContext?.player?.id,
              promptId: Number(promptResponse.promptId),
              mediaGroup: promptResponse.post!!,
            };
            setPostPlayerRequest(request);
          });
        })
        .finally(() => {
          dispatch({ type: "loaded" });
        });
    } else {
      dispatch({ type: "loading" });
      VideoProjectServices.get(contentId)
        .then(videoProject => {
          const request: PostPlayerRequest = {
            messageToPost: `${videoProject.messageToPost}`,
            playerId: userContext?.player?.id,
            postId: Number(videoProject.post?.postId),
            mediaGroup: videoProject.editedMedia!!,
            socialNetworkMessages: getSocialNetworkMessages(videoProject.socialNetworks!!),
            scheduledDatetimeUtc: postPlayerRequest?.scheduledDatetimeUtc,
            isAutoPost: videoProject.isAutoPost,
            // sendMode: videoProject.post.se
            // scheduledDatetimeUtc: videoProject.
          };
          setPostPlayerRequest(request);
        })
        .finally(() => {
          dispatch({ type: "loaded" });
        });
    }
  }, [contentId, contentType, playerSn]);

  const close = () => {
    dispatch({ type: "close" });
    closeHandler();
  };

  const onSuccess = (isAutoPost: boolean, sendMode: SendModeType): void => {
    setShowSuccessPage(true);
    setPostPlayerRequest({
      ...postPlayerRequest!,
      sendMode,
      isAutoPost,
    });
  };

  return (
    <Modal closeIcon size={"small"} open={state.open} onClose={close}>
      <Modal.Header>
        <Header.Content>
          <label className="postFailedTitle">New Post</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content className="repostFormModalContent">
        {!showSuccessPage && !state.loading && postPlayerRequest !== undefined && (
          <PostModalForm
            playerId={userContext?.player?.id!!}
            thumbnail={thumbnail}
            postPlayerRequest={postPlayerRequest}
            onSuccess={onSuccess}
          />
        )}

        {showSuccessPage && (
          <PlayerPostSuccess sendMode={postPlayerRequest?.sendMode!!} isAutoPost={postPlayerRequest?.isAutoPost} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export const RepostModal = RepostModalFC;
