import * as React from "react";
import { Menu } from "semantic-ui-react";
import classNames from "classnames";

import { UsersService } from "../../services/AccountsService";
import { MenuElement, BasicElement } from "../../interfaces";
import { AppContext } from "../../providers";
import { noSpaces, URLS } from "../../utils";

import "./styles.scss";
interface Props {
  history: any;
  readyToContinue?: boolean;
  menu: MenuElement[];
  logoutRedirectPath?: string;

  canGoToMenu: (menuItem: MenuElement, currentStep?: string) => boolean;
}

const SideBarFC: React.FC<Props> = ({
  history,
  readyToContinue = true,
  menu,
  logoutRedirectPath = URLS.login,
  canGoToMenu,
}) => {
  const path = history.location?.pathname;
  const redirectLink = () => history.push(logoutRedirectPath);

  const renderItem = (option: BasicElement, index: number, signUpStep?: string): JSX.Element => {
    return (
      <Menu.Item
        data-elm-id={"sideBar_" + noSpaces(option.title)}
        key={`${index}`}
        name={option.title}
        className={classNames(option.path === path ? "selectedItem" : "basicItem")}
        onClick={() => {
          if (!readyToContinue) {
            alert("Please save or cancel before leaving this display");
          } else {
            history.push(option.path);
          }
        }}
        disabled={path !== option.path && !canGoToMenu(option, signUpStep)}
      />
    );
  };

  const renderMultiItem = (option: MenuElement, index: number, signUpStep?: string): JSX.Element => {
    return (
      <div>
        <Menu.Item
          key={`${index}`}
          name={option.title}
          className={classNames(option.path === path ? "selectedItem" : "basicItem")}
          onClick={() => {
            if (!readyToContinue) {
              alert("Please save or cancel before leaving this display");
            } else {
              history.push(option.path);
            }
          }}
          disabled={path !== option.path && !canGoToMenu(option, signUpStep)}
        />
        {option.subMenu &&
          (option.path === path || option.subMenu!.filter(subOption => subOption.path === path).length > 0) && (
            <Menu.Item key={`submenu_${index}_`}>
              {option.subMenu &&
                option.subMenu.map((subOption, subIndex) => renderItem(subOption, subIndex, signUpStep))}
            </Menu.Item>
          )}
      </div>
    );
  };

  return (
    <div className="sideBarContainer">
      <AppContext.Consumer>
        {({ setUserContext, userContext }) => {
          return (
            <Menu pointing vertical className={"menuBox"}>
              {menu.map((option, index) => {
                return option.subMenu && option.subMenu!.length > 0
                  ? renderMultiItem(option, index, userContext?.signUpStep)
                  : renderItem(option, index, userContext?.signUpStep);
              })}
              <Menu.Item
                data-elm-id={"sideBar_signOut"}
                name={"Sign out"}
                active={false}
                onClick={async () => {
                  try {
                    await UsersService.logout();
                    setUserContext();
                    redirectLink();
                  } catch (e) {
                    alert(e);
                  }
                }}
              />
            </Menu>
          );
        }}
      </AppContext.Consumer>
    </div>
  );
};

export const SideBarBase = SideBarFC;
