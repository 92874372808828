import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Card, CardContent, CardDescription, CardHeader, CardMeta, Grid } from "semantic-ui-react";

import { MiniCard } from "../../../../../../../components";
import { PlayerQuickAction, ActionStyleLoweCase } from "../../../../../../../interfaces";

import { URLS } from "../../../../../../../utils";

import "./styles.scss";

interface OwnProps {
  action: PlayerQuickAction;
}
type Props = OwnProps & RouteComponentProps;

const QuickActionsFC: React.FC<Props> = ({ action, history }) => {
  const { title, subtitle, actionStyle, actionTarget } = action;

  React.useEffect(() => {}, []);

  const cardPressed = (): void => {
    const routesMapper: { [key: string]: any } = {
      CUSTOMIZATIONS: {
        name: URLS.player.settings,
        locationValues: {
          activeSection: "CUSTOMIZATIONS",
        },
      },
      SOCIAL_NETWORKS: {
        name: URLS.player.settings,
        locationValues: {
          activeSection: "SOCIAL_NETWORKS",
        },
      },
      PROMPT_LIBRARY: { name: URLS.player.library, locationValues: { activeSection: "PROMPTS" } },
      SCRIPT_LIBRARY: { name: URLS.player.library, locationValues: { activeSection: "SCRIPTS" } },
      PROJECT_LIBRARY: { name: URLS.player.library, locationValues: { activeSection: "VIDEO_CATALYST" } },
      NEW_VIDEO_PROJECT: { name: URLS.player.newVideoProject, locationValues: {} },
    };

    history.push(routesMapper[actionTarget].name, routesMapper[actionTarget].locationValues);
  };

  const actionStyleLC: "default" | "urgent" | "premium" = actionStyle.toLocaleLowerCase() as ActionStyleLoweCase;

  return (
    <Card onClick={cardPressed} className={"cardBorder_" + actionStyleLC}>
      <CardContent>
        <CardHeader>{title}</CardHeader>
        <br /> <br />
        <CardDescription className={"quickActionSubtitle"} content={subtitle} />
      </CardContent>
    </Card>
  );
};

export const QuickActionCard = withRouter(QuickActionsFC);
