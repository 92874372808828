import { useState, useEffect } from "react";
import { HashTagsServices } from "../../../../../../../services";
import { AppUserContext } from "../../../../../../../providers";
import { Prompt } from "../../../../../../../interfaces";

export const useMessageToRepost = (userContext: AppUserContext, prompt: Prompt) => {
  const [messageToPost, setMessageToPost] = useState<string | undefined>(undefined);
  const playerId = userContext.player?.id;

  useEffect(() => {
    if (playerId) {
      HashTagsServices.get(playerId).then(response => {
        const hashtags = response.map(hashtag => hashtag.content);
        let message = prompt.message;

        const hashtagsToInclude = hashtags?.filter(hashtag => !message.includes(hashtag));
        if (
          hashtagsToInclude &&
          hashtagsToInclude.length > 0 &&
          userContext?.player!!.addHashTagAutomatically &&
          prompt?.allowHashtags !== false
        ) {
          message = message + `\n#${hashtagsToInclude.join(" #")}`;
        }
        if (
          userContext?.profile?.complianceDisclaimer &&
          prompt?.allowComplianceDisclaimer !== false &&
          !message.includes(userContext?.profile?.complianceDisclaimer)
        ) {
          message = message + userContext?.profile?.complianceDisclaimer;
        }

        setMessageToPost(message);
      });
    }
  }, [playerId, prompt]);

  return { messageToPost };
};
