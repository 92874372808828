import * as React from "react";
import { useIntl } from "react-intl";
import { Button, Grid } from "semantic-ui-react";
import moment from "moment";

import { saveElementToFirebase } from "../../../../../utils";
import { ConfigContext } from "../../../../../contexts/appContexts";
import { AppContext } from "../../../../../providers";

import { useInterval } from "../../hooks";
import { descriptors, RecordPageType } from "../../descriptors";
interface Props {
  webcamRef: any;
  disabled: boolean;

  startScroll: () => void;
  pauseScroll: () => void;
  stopScroll: () => void;
  startOverHandler: () => void;
  doneHandler: (finalUrl: string) => void;
  loadPreview: (previewUrl: string) => void;
  loadingHandler: (loading: boolean) => void;
}

const dataElmId = "recordActionSection";

const RecordActionSectionFC: React.FC<Props> = ({
  webcamRef,
  disabled,
  startScroll,
  stopScroll,
  pauseScroll,
  startOverHandler,
  doneHandler,
  loadPreview,
  loadingHandler,
}) => {
  const { formatMessage } = useIntl();
  const { firebase } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);

  const mediaRecorderRef = React.useRef<any>(null);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const [recordingStatusLabel, setRecordingStatusLabel] = React.useState<"Paused" | "Recording">("Paused");
  const [recordingStatus, setRecordingStatus] = React.useState<"toStart" | "Paused" | "Recording" | "Done">("toStart");
  const [isRecording, setIsRecording] = React.useState<boolean>(false);
  const [counter, setCounter] = React.useState<number>(0);

  React.useEffect(() => {
    if (recordedChunks.length && recordingStatus === "Done") {
      handleFinished();
    }

    if (recordedChunks.length && recordingStatus === "Paused") {
      handlePreview();
    }
  }, [recordedChunks, recordingStatus]);

  useInterval(() => {
    if (isRecording) {
      setCounter(prevCount => prevCount + 1);
    }
  }, 1000);

  const reset = (): void => {
    stopScroll();
    setCounter(0);
    handleStopCaptureClick();
    startOverHandler();
    setIsRecording(false);
  };

  const setRecordStatus = (status: "Paused" | "Recording"): void => {
    setRecordingStatusLabel(status);
    setRecordingStatus(status);
  };

  const handleStartCaptureClick = React.useCallback(() => {
    if (webcamRef && webcamRef.current) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream!!, {
        mimeType: "video/webm",
      });
      mediaRecorderRef.current!!.addEventListener("dataavailable", handleDataAvailable);
      mediaRecorderRef.current!!.start();
    }
  }, [webcamRef, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    (response: any) => {
      const { data } = response;

      if (data.size > 0) {
        setRecordedChunks(prev => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  }, [mediaRecorderRef, webcamRef]);

  const handleResumeCaptureClick = React.useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.resume();
    }
  }, [mediaRecorderRef, webcamRef]);

  const handlePauseCaptureClick = React.useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.requestData();
      mediaRecorderRef.current.pause();
    }
  }, [mediaRecorderRef, webcamRef]);

  const loadPreviewToFirebase = async (blob: Blob): Promise<void> => {
    const url = await saveElementToFirebase(
      firebase,
      "teleprompter/" + userContext?.account.userId + "/",
      blob,
      "preview_video"
    );
    loadPreview(url);
  };

  const loadFinalToFirebase = async (blob: Blob): Promise<void> => {
    const timestamp = new Date().getTime();

    loadingHandler(true);
    const url = await saveElementToFirebase(
      firebase,
      "teleprompter/" + userContext?.account.userId + "/",
      blob,
      "final_video" + timestamp
    );

    loadingHandler(false);
    doneHandler(url);
  };

  const handleFinished = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });

      loadFinalToFirebase(blob);
    }
  }, [recordedChunks]);

  const handlePreview = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });

      loadPreviewToFirebase(blob);
    }
  }, [recordedChunks]);

  const recordAction = (): void => {
    if (isRecording) {
      handlePauseCaptureClick();
      setRecordStatus("Paused");
      pauseScroll();
      setIsRecording(false);
    } else {
      setRecordStatus("Recording");
      setIsRecording(true);
      setCounter(0);
      startScroll();
      handleStartCaptureClick();
    }
  };

  const startOverAction = (): void => {
    setRecordingStatusLabel("Paused");
    setRecordingStatus("toStart");
    reset();
  };

  const resumeAction = (): void => {
    setRecordStatus("Recording");
    setIsRecording(true);
    startScroll();
    handleResumeCaptureClick();
  };

  const doneRecordingAction = (): void => {
    setRecordingStatus("Done");
    stopScroll();
    handleStopCaptureClick();
  };

  const nothingRecorded = recordingStatus === "toStart";

  return (
    <>
      <Grid.Column width={6}>
        <Grid>
          <Grid.Column width={5} className="recordInfoColumn">
            <p>
              {formatMessage(
                { ...descriptors[RecordPageType.counterControl] },
                {
                  status: recordingStatusLabel,
                  time: moment.utc(counter * 1000).format("mm:ss"),
                }
              )}
            </p>
          </Grid.Column>
          <Grid.Column width={6} centered>
            {recordingStatus === "toStart" || recordingStatus === "Recording" ? (
              <Button disabled={disabled} fluid primary data-elm-id={`${dataElmId}ActionBtn`} onClick={recordAction}>
                {formatMessage(
                  { ...descriptors[RecordPageType.actionBtn] },
                  { status: isRecording ? "Stop" : "Start" }
                )}
              </Button>
            ) : (
              <Button secondary data-elm-id={`${dataElmId}DoneBtn`} onClick={resumeAction}>
                {formatMessage({ ...descriptors[RecordPageType.resumeBtn] })}
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={5}>
            <Button
              secondary
              data-elm-id={`${dataElmId}StartOverBtn`}
              onClick={startOverAction}
              disabled={nothingRecorded}
            >
              {formatMessage({ ...descriptors[RecordPageType.startOverBtn] })}
            </Button>
          </Grid.Column>
        </Grid>
      </Grid.Column>

      <Grid.Column width={6}>
        {recordingStatus === "Paused" && (
          <Button primary data-elm-id={`${dataElmId}DoneBtn`} onClick={doneRecordingAction}>
            {formatMessage({ ...descriptors[RecordPageType.doneBtn] })}
          </Button>
        )}
      </Grid.Column>
    </>
  );
};

export const RecordActionSection = RecordActionSectionFC;
