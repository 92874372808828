import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Header, Grid, Icon, Button } from "semantic-ui-react";
import TextareaAutosize from "react-textarea-autosize";

import { UserGroups, VideoProjectMessage } from "../../../../../interfaces";
import { AppContext } from "../../../../../providers";
import { VideoProjectServices } from "../../../../../services";
import { printDateToChat } from "../../../../../utils";

import "./styles.scss";

import { descriptors, VideoProjectBubbleDetailsType } from "./descriptors";
interface OwnProps {
  videoProjectId: number;
  messages: VideoProjectMessage[];
  ownerBoxTitle?: string;
  placeholder?: string;
  noteMessage?: string;
  needsAck?: boolean;
  onSuccess: () => void;
}

const dataElmId = "VideoProjectBubbleDetails";
type Props = OwnProps;

/**
 * Represents a Video project Bubble detail
 */

const IntegratedBubbleDetails: React.FC<Props> = ({
  videoProjectId,
  messages,
  ownerBoxTitle,
  placeholder = "Respond here...",
  noteMessage,
  needsAck = true,
  onSuccess,
}) => {
  const { userContext } = React.useContext(AppContext);
  const [messageToSend, setMessageToSend] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const isEditor = userContext?.account.scGroups!!.some(g => g === UserGroups.EDITOR || g === UserGroups.ADMIN);

  const sendMessage = (): void => {
    setLoading(true);
    const action = isEditor ? VideoProjectServices.sendMessage : VideoProjectServices.requestRevision;
    action(videoProjectId, messageToSend)
      .catch(() => {
        alert("Try again");
      })
      .finally(() => {
        onSuccess();
        setMessageToSend("");
        setLoading(false);
      });
  };

  return (
    <Grid className={"videoProjectContainer"}>
      <Header as="h3" textAlign="left" className="noMargin noPadding">
        <FormattedMessage {...descriptors[VideoProjectBubbleDetailsType.title]} />
        {needsAck && <Icon name="exclamation circle" className={"infoIcon"} link />}
      </Header>
      <Grid.Row className={"videoProjectBubble"}>
        <Grid>
          {messages &&
            messages.map(({ userName, content, createdDate, userId }, index) => {
              const ownBubble = userContext?.account.userId === userId;
              return (
                <Grid.Row className={"noPadding videoProjectGrid"} key={"bubble_" + index}>
                  <p
                    data-elm-id={dataElmId + "MessageInfo"}
                    className={ownBubble ? "ownInfo infoRight" : "ownInfo infoLeft"}
                  >
                    <strong data-elm-id={dataElmId + "Username"}>{userName}</strong>{" "}
                    {" " + printDateToChat(createdDate)}
                  </p>
                  <div
                    className={
                      ownBubble ? "videoProjectMessage selected ownMessage" : "videoProjectMessage guessContent"
                    }
                  >
                    <p data-elm-id={dataElmId + "Content"} className={ownBubble ? "messageContent" : "guess"}>
                      {content}
                    </p>
                  </div>
                </Grid.Row>
              );
            })}
        </Grid>
      </Grid.Row>
      {loading && <div className="loader"></div>}
      {ownerBoxTitle && (
        <Header as="h3" textAlign="left" className="noMargin noPadding">
          {ownerBoxTitle}
        </Header>
      )}
      <Grid.Row className={!isEditor ? "videoProjectBubble noFlex" : "videoProjectBubble"}>
        <TextareaAutosize
          data-elm-id={dataElmId + "MessageToSend"}
          id="messageToSend"
          className="messageField"
          name="messageToSend"
          placeholder={placeholder}
          rows={3}
          value={messageToSend}
          onKeyUp={e => {
            if (e.key === "Enter") {
              if (isEditor) {
                e.preventDefault();
                sendMessage();
              }
            }
          }}
          onChange={data => {
            setMessageToSend!(data.currentTarget.value);
          }}
        />
        {isEditor && <Icon name="send" className={"sendIcon"} onClick={sendMessage} />}
        {noteMessage && <p className="noteMessage">{noteMessage}</p>}
        {!isEditor && (
          <Button
            data-elm-id={dataElmId + "SubmitBtn"}
            floated="right"
            className={"primary"}
            onClick={() => sendMessage()}
            disabled={messageToSend === ""}
            loading={loading}
          >
            <FormattedMessage {...descriptors[VideoProjectBubbleDetailsType.submit]} />
          </Button>
        )}
      </Grid.Row>
    </Grid>
  );
};

export const VideoProjectBubbleDetails = IntegratedBubbleDetails;
