import * as React from "react";
import * as DTO from "../../../interfaces/dtos";
import { RouteComponentProps } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";

import { TitleSection, AdminSideBar, SideBar } from "../../../components";

import { WhiteContainer } from "../../../styling/baseStyle";

import "./styles.scss";

import { VideoScriptsServices } from "../../../services";
import { useState } from "react";
import { VideoScriptForm } from "./components/VideoScriptForm";
import { VideoScriptRequest } from "../../../interfaces/dtos";
import { coachWithWritePermission, URLS } from "../../../utils";
import { ScriptDetails } from "./components/VideoScriptDetails";
import { AppContext } from "../../../providers";
import { NotificationModal } from "./components/NotificationModal";

type Props = RouteComponentProps;

const VideoScriptFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { userContext } = React.useContext(AppContext);
  const [videoScript, setVideoScript] = useState<DTO.VideoScript>();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [values, setValues] = useState<any>();
  const [savingStatus, setSavingStatus] = useState<string | undefined>();

  React.useEffect(() => {
    const getScript = async (scriptId: number) => {
      const result = await VideoScriptsServices.get(scriptId);
      setVideoScript(result);
    };

    if (params["id"]) {
      getScript(params["id"]).catch(console.error);
    } else {
      setVideoScript({
        playerId: userContext?.player?.id!!,
        title: "",
        content: "",
        categories: [],
      });
    }
  }, []);

  const deleteScript = async () => {
    if (window.confirm("Are you sure you want to delete this script?")) {
      VideoScriptsServices.delete(videoScript?.videoScriptId!!).then(() => {
        history.push(URLS.player.library, { activeSection: "SCRIPT" });
      });
    }
  };

  const saveScript = async () => {
    setLoading(true);
    const request: VideoScriptRequest = {
      playerId: values.playerId,
      title: values.title,
      content: values.content,
      categories: [],
    };
    if (values?.videoScriptId) {
      await VideoScriptsServices.update(values.videoScriptId, request)
        .catch(e => {
          console.error(e);
          alert("Error saving script");
        })
        .then(updatedScript => {
          if (updatedScript) {
            setVideoScript(updatedScript);
            history.replace(URLS.player.videoScript.replace(":id", `${updatedScript.videoScriptId}`));
          }
        });
    } else {
      await VideoScriptsServices.create(request)
        .catch(e => {
          console.error(e);
          alert("Error saving script");
        })
        .then(newScript => {
          if (newScript) {
            setVideoScript(newScript);
            history.replace(URLS.player.videoScript.replace(":id", `${newScript.videoScriptId}`));
          }
        });
    }
    setLoading(false);
    setSavingStatus("Saved!");
    setTimeout(() => {
      setSavingStatus(undefined);
    }, 5000);
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <div className={"hugePadding videoScriptPage"}>
        <TitleSection
          title={"Video Script"}
          subtitle={savingStatus}
          ready={true}
          showNextOption={true}
          showLinkOption={true}
          customNextLabel={"Record Video"}
          handleNextAction={() =>
            history.push(URLS.player.recordVideo, { script: videoScript?.content, id: videoScript?.videoScriptId })
          }
          disableButtons={!isValid}
          titleSize={6}
          buttonsSize={9}
          nextButtonSize={5}
          customLinkSection={
            <Grid.Column width={10}>
              <Grid columns={2}>
                {videoScript?.videoScriptId && videoScript?.playerId !== undefined && (
                  <Grid.Column>
                    <Button
                      type="submit"
                      data-elm-id={"deleteVideoScript"}
                      loading={loading}
                      fluid
                      className={"secondary rounded buttonRight"}
                      onClick={deleteScript}
                    >
                      Delete
                    </Button>
                  </Grid.Column>
                )}
                {(videoScript?.playerId || videoScript?.videoScriptId == undefined) && (
                  <Grid.Column>
                    <Button
                      type="submit"
                      data-elm-id={"scriptNotification"}
                      loading={loading}
                      fluid
                      className={"primary rounded buttonRight noPaddingHorizontal"}
                      onClick={saveScript}
                    >
                      Save
                    </Button>
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Column>
          }
        />
        <Grid columns={2}>
          <Grid.Column>
            <Grid.Row className={"videoScriptFormContainer"}>
              <Grid.Column>
                {videoScript && (
                  <VideoScriptForm
                    videoScript={videoScript}
                    updatingHandler={setValues}
                    validationHandler={setIsValid}
                    disabled={false}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>

          <Grid.Column>
            <Grid.Row className={"videoScriptFormContainer"}>
              <Grid.Column>
                {videoScript?.videoScriptId && <ScriptDetails videoScriptId={videoScript?.videoScriptId} />}
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid>

        {showNotificationModal && videoScript && (
          <NotificationModal
            opened={showNotificationModal}
            script={videoScript}
            acceptHandler={() => setShowNotificationModal(false)}
            rejectHandler={() => setShowNotificationModal(false)}
          />
        )}
      </div>
    </WhiteContainer>
  );
};

export const PlayerVideoScriptPage = VideoScriptFC;
