import { RcFile } from "antd/lib/upload";
import { Category } from "../models";
import { EligibilityCriteria } from "./Campaign";
import { FileErrorDescription } from "../models";
import { UploadTask } from "firebase/storage";

export type MediaType = "VIDEO" | "IMAGE" | "MIX" | "REELS";
export type MediaCategory = "VIDEO" | "IMAGE";

export type DeliveryMode = "RANDOM_DELIVERY" | "SPECIFIC_DATE" | "WELCOME_PROMPT" | "LIBRARY";
// NOTE: "RANDOM_DELIVERY" | "SPECIFIC_DATE" were deprecated
export type PromptStatus = "ACTIVE" | "DISABLED" | "DRAFT";

export enum PromptType {
  FEED = "FEED",
  REELS = "REELS",
}

export interface PlayerPrompt {
  deliveryDateTime?: string;
  deliveryErrorMessage?: string;
  id: number;
  isAutoPost: boolean;
  isSaved: boolean;
  lastFailedDeliveryTimestamp: string;
  origin: string;
  playerId: number;
  prompt: Prompt;
}

export interface Prompt {
  promptId?: string | number;
  coachId?: string;
  title: string;
  message: string;
  hashtags?: string;
  richMessage?: string;
  status: PromptStatus;
  deliveryMode: DeliveryMode;
  creationDate?: string;
  deliveryDatetimeUtc?: string | null;
  eligibilityCriteria?: EligibilityCriteria[];
  mediaMessage?: MediaGroup;
  post?: MediaGroup;
  categories?: Category[];
  allowHashtags?: boolean;
  allowShortLink?: boolean;
  allowComplianceDisclaimer?: boolean;
  type?: PromptType;
}

export interface SelectedPrompts {
  [key: string]: SelectedPromptInfo;
}

export interface SelectedPromptInfo {
  categoryIds: string[];
  title: string;
  post?: MediaGroup;
  prompt?: Prompt;
  campaignPromptId?: string;
}

export interface PromptRequest extends Prompt {
  categoryIds?: string[];
}

export interface PromptPreview extends PromptRequest {
  thumbnail?: string;
  uploadTask?: UploadTask;
  selected?: boolean;
  withError?: boolean;
  loading: boolean;
  loadStatus?: "LOADING" | "SUBMITTED" | "FAILED";
  uid: string;
}

export interface PromptBulkRequest {
  coachId: string;
  deliveryMode: DeliveryMode;
  promptList?: PromptRequest[];
}

export interface PromptBulkResponse {
  error: SCError[];
  successCreated: PromptSuccessResponse[];
}

interface SCError {
  error: string;
  title: string;
}

interface PromptSuccessResponse {
  promptId: number;
  title: string;
}

export interface PromptPreviewObject extends Prompt {
  deliveryTimeUtc?: string | null;
  deliveryDateUtc?: string | null;
  media?: Media[];
}

export interface MediaGroup {
  mediaGroupId?: number;
  type: MediaType;
  media: Media[];
}

export interface Media {
  id: number;
  sortOrder: number;
  category: MediaCategory;
  uri: string;
  thumbnailUri?: string;
  type: string;
  extension: string;
  downloadLink?: string;
  validAspectRatio?: boolean; // To indicate if the images has correct IG aspect ratio
  validDimension?: boolean; // To indicate if the images has correct (REELS)
  imageFile?: File;
  fileName?: string;
  errors?: FileErrorDescription;
  transcripts?: MediaTranscript[];
}

export interface MediaTranscript {
  id?: number;
  content?: string;
  createdDate?: string;
}

export interface UploadProgressMap {
  [mediaId: number]: { progress: number; task: UploadTask | undefined };
}

export interface SocialFiles extends RcFile {
  uploadTask?: UploadTask;
  thumbnail?: string;
  progress?: number;
}
