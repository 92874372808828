import { client as axios } from "./axiosClient";
import { Constants, catchServiceException } from "@socialcoach/services";
import * as DTO from "../interfaces";

const HASHTAG_SERVICE = `${Constants.API_URL}/accounts/players/hashtags`;
const COACH_HASHTAG_SERVICE = `${Constants.API_URL}/accounts/coach/hashtags`;

export class HashTagsServices {
  @catchServiceException()
  static async get(playerId: number): Promise<DTO.HashTagElementResponse[]> {
    const { data } = await axios.post<DTO.HashTagElementResponse[]>(`${HASHTAG_SERVICE}/${playerId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async update(playerId: number, hashtags: DTO.HashTagUpdateRequest): Promise<DTO.HashTagElementResponse[]> {
    const { data } = await axios.put<DTO.HashTagElementResponse[]>(
      `${HASHTAG_SERVICE}/${playerId}`,
      {
        ...hashtags,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }

  @catchServiceException()
  static async getCoachHashtags(coachId: string): Promise<DTO.HashTagElementResponse[]> {
    const { data } = await axios.get<DTO.HashTagElementResponse[]>(`${COACH_HASHTAG_SERVICE}/${coachId}`, {
      withCredentials: true,
    });
    return data;
  }

  @catchServiceException()
  static async updateCoachHashtags(
    coachId: string,
    hashtags: DTO.HashTagUpdateRequest
  ): Promise<DTO.HashTagElementResponse[]> {
    const { data } = await axios.put<DTO.HashTagElementResponse[]>(
      `${COACH_HASHTAG_SERVICE}/${coachId}`,
      {
        ...hashtags,
      },
      {
        withCredentials: true,
      }
    );
    return data;
  }
}
