import * as React from "react";
import { Modal, Header } from "semantic-ui-react";

import { PostPlayerRequest, SendModeType, VideoProjectDetail } from "../../../../../interfaces";
import { AppContext } from "../../../../../providers";
import { PostModalForm, PlayerPostSuccess } from "../../../../../components";

import { modalReducer } from "./modalReducer";
import { useMessageToRepost } from "./hooks";
import { usePlayerSocialNetworks } from "../../../../../reducers";
import { getSocialNetworkMessages } from "../../../../../utils";

interface OwnProps {
  opened: boolean;
  videoProject: VideoProjectDetail;

  closeHandler: () => void;
}

type Props = OwnProps;

/**
 * Repost Flow Modal Components
 */
const RepostModalFC: React.FC<Props> = ({ opened, videoProject, closeHandler }) => {
  const { userContext } = React.useContext(AppContext);
  const { playerSn } = usePlayerSocialNetworks();

  const [state, dispatch] = React.useReducer(modalReducer, { open: opened, loading: false, success: false });
  const [postPlayerRequest, setPostPlayerRequest] = React.useState<PostPlayerRequest | undefined>(undefined);

  //const { messageToPost } = useMessageToRepost(userContext!!, videoProject);

  React.useEffect(() => {
    if (videoProject !== undefined) {
      const request: PostPlayerRequest = {
        messageToPost: `${videoProject.messageToPost}`,
        playerId: userContext?.player?.id,
        postId: Number(videoProject.post?.postId),
        mediaGroup: videoProject.editedMedia!!,
        socialNetworkMessages: getSocialNetworkMessages(videoProject.socialNetworks!!),
        scheduledDatetimeUtc: postPlayerRequest?.scheduledDatetimeUtc,
        isAutoPost: videoProject.isAutoPost,
        // sendMode: videoProject.post.se
        // scheduledDatetimeUtc: videoProject.
      };
      setPostPlayerRequest(request);
    }
  }, [videoProject]);

  const close = () => {
    dispatch({ type: "close" });
    closeHandler();
  };

  const onSuccess = (isAutoPost: boolean, sendMode: SendModeType): void => {
    dispatch({ type: "success" });
    setPostPlayerRequest({ ...postPlayerRequest!!, sendMode, isAutoPost });
  };

  return (
    <Modal closeIcon size={"small"} open={state.open} onClose={close}>
      <Modal.Header>
        <Header.Content>
          <label className="postFailedTitle">New Post</label>
        </Header.Content>
      </Modal.Header>

      <Modal.Content className="repostFormModalContent">
        {!state.success && !state.loading && videoProject && postPlayerRequest && (
          <PostModalForm
            playerId={userContext!!.player!.id}
            thumbnail={videoProject.editedMedia.media[0].thumbnailUri}
            postPlayerRequest={postPlayerRequest}
            onSuccess={onSuccess}
          />
        )}

        {state.success && (
          <PlayerPostSuccess sendMode={postPlayerRequest?.sendMode!!} isAutoPost={postPlayerRequest?.isAutoPost} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export const RepostModal = RepostModalFC;
