import { defineMessages } from "react-intl";

export enum CampaignType {
  campaignTitle = "CampaignType.campaignTitle",
  deliveryLabel = "CampaignType.deliveryLabel",
  randomDelivery = "CampaignType.randomDelivery",
  specificDate = "CampaignType.specificDate",
  orderedRandomDelivery = "CampaignType.orderedRandomDelivery",
  deliveryTypeLabel = "CampaignType.deliveryTypeLabel",
  autoPost = "CampaignType.autoPost",
  noAutoPost = "CampaignType.noAutoPost",
  autoPostSocialNetworks = "CampaignType.autoPostSocialNetworks",
  randomDaysDescription = "CampaignType.randomDaysDescription",
  deliveryTimeDescription = "CampaignType.deliveryTimeDescription",
  nextBtn = "CampaignType.nextBtn",
  cancelBtn = "CampaignType.cancel",
  pstZoneMessage = "CampaignType.pstZoneMessage",
  customDurationLabel = "CampaignType.customDurationLabel",
  startDateLabel = "CampaignType.startDateLabel",
  endDateLabel = "CampaignType.endDateLabel",
  campaignDurationHoverDescription = "CampaignType.campaignDurationHoverDescription",
  deliveryModeDescription = "CampaignType.deliveryModeDescription",
  deliveryTypeDescription = "CampaignType.deliveryTypeDescription",
  campaignDurationDescription = "CampaignType.campaignDurationDescription",

  // TODO: DELETEABLES
  titlePlaceholder = "CampaignType.titlePlaceholder",
  messagePlaceholder = "CampaignType.messagePlaceholder",
  uploadFilePlaceholder = "CampaignType.uploadFilePlaceholder",
  socialNetworksDescription = "CampaignType.socialNetworksDescription",
  categoryPlaceholder = "CampaignType.categoryPlaceholder",

  addCategoryBtn = "CampaignType.addCategory",

  categoryInfo = "CampaignType.categoryInfo",
  categoryNotSaved = "CampaignType.categoryNotSaved",
}

export const en = {
  [CampaignType.campaignTitle]: "Title",
  [CampaignType.deliveryLabel]: "Delivery Mode",
  [CampaignType.deliveryTypeLabel]: "Delivery Type",
  [CampaignType.customDurationLabel]: "Campaign Duration (optional)",

  [CampaignType.deliveryModeDescription]:
    "How would you like the Prompts in this Campaign to be organized for posting?",
  [CampaignType.orderedRandomDelivery]: "Ordered",
  [CampaignType.randomDelivery]: "Randomized",
  [CampaignType.specificDate]: "Scheduled",

  [CampaignType.deliveryTypeDescription]:
    "How would you like the Prompts in this Campaign to be sent to your Players' accounts?",

  [CampaignType.autoPost]: "Auto Post",
  [CampaignType.noAutoPost]: "Push Notification",
  [CampaignType.socialNetworksDescription]: "Select the social network(s) you would like to Auto Post to:",

  [CampaignType.randomDaysDescription]:
    "What days and time should we send randomized Prompts to your Players' accounts?",
  [CampaignType.deliveryTimeDescription]: "Delivery Time (8:00 AM PST)",
  [CampaignType.nextBtn]: "Next",
  [CampaignType.cancelBtn]: "Cancel",
  [CampaignType.titlePlaceholder]: "Enter Name",

  [CampaignType.autoPostSocialNetworks]: "How would you like the Prompts in this Campaign to be organized for posting?",
  [CampaignType.startDateLabel]: "Start Date (optional)",
  [CampaignType.endDateLabel]: "End Date (optional)",

  [CampaignType.messagePlaceholder]: "Write Message",
  [CampaignType.uploadFilePlaceholder]: "Select a file",
  [CampaignType.categoryPlaceholder]: "Add Category",

  [CampaignType.pstZoneMessage]: "Prompts will be sent in Pacific Time",

  [CampaignType.campaignDurationDescription]: "When would you like thi Campaign to begin and/or end for your Players?",
  [CampaignType.addCategoryBtn]: "Add Category",
  [CampaignType.campaignDurationHoverDescription]:
    "If you want your Campaign to start and/or end on specific dates, select the date(s) below. If left blank, the default Start Date for a Campaign is the day after the Campaign was created, and the default End Date is indefinite (it ends for each Player when they’ve received all the Prompts included in the Campaign).",

  [CampaignType.categoryInfo]: "Assign a category to your prompt to keep your prompts organized within the Library",
  [CampaignType.categoryNotSaved]: 'Please click "Add Category" to save your category',
};

export const descriptors = defineMessages({
  [CampaignType.categoryNotSaved]: {
    id: CampaignType.categoryNotSaved,
    defaultMessage: en[CampaignType.categoryNotSaved],
    description: "-",
  },
  [CampaignType.pstZoneMessage]: {
    id: CampaignType.pstZoneMessage,
    defaultMessage: en[CampaignType.pstZoneMessage],
    description: "-",
  },
  [CampaignType.categoryInfo]: {
    id: CampaignType.categoryInfo,
    defaultMessage: en[CampaignType.categoryInfo],
    description: "-",
  },
  [CampaignType.deliveryTimeDescription]: {
    id: CampaignType.deliveryTimeDescription,
    defaultMessage: en[CampaignType.deliveryTimeDescription],
    description: "-",
  },
  [CampaignType.deliveryLabel]: {
    id: CampaignType.deliveryLabel,
    defaultMessage: en[CampaignType.deliveryLabel],
    description: "-",
  },
  [CampaignType.autoPost]: {
    id: CampaignType.autoPost,
    defaultMessage: en[CampaignType.autoPost],
    description: "-",
  },
  [CampaignType.randomDaysDescription]: {
    id: CampaignType.randomDaysDescription,
    defaultMessage: en[CampaignType.randomDaysDescription],
    description: "-",
  },
  [CampaignType.deliveryTypeLabel]: {
    id: CampaignType.deliveryTypeLabel,
    defaultMessage: en[CampaignType.deliveryTypeLabel],
    description: "-",
  },
  [CampaignType.randomDelivery]: {
    id: CampaignType.randomDelivery,
    defaultMessage: en[CampaignType.randomDelivery],
    description: "-",
  },
  [CampaignType.orderedRandomDelivery]: {
    id: CampaignType.orderedRandomDelivery,
    defaultMessage: en[CampaignType.orderedRandomDelivery],
    description: "-",
  },
  [CampaignType.specificDate]: {
    id: CampaignType.specificDate,
    defaultMessage: en[CampaignType.specificDate],
    description: "-",
  },
  [CampaignType.autoPostSocialNetworks]: {
    id: CampaignType.autoPostSocialNetworks,
    defaultMessage: en[CampaignType.autoPostSocialNetworks],
    description: "-",
  },
  [CampaignType.campaignTitle]: {
    id: CampaignType.campaignTitle,
    defaultMessage: en[CampaignType.campaignTitle],
    description: "-",
  },

  [CampaignType.startDateLabel]: {
    id: CampaignType.startDateLabel,
    defaultMessage: en[CampaignType.startDateLabel],
    description: "-",
  },
  [CampaignType.endDateLabel]: {
    id: CampaignType.endDateLabel,
    defaultMessage: en[CampaignType.endDateLabel],
    description: "-",
  },
  [CampaignType.noAutoPost]: {
    id: CampaignType.noAutoPost,
    defaultMessage: en[CampaignType.noAutoPost],
    description: "-",
  },
  [CampaignType.customDurationLabel]: {
    id: CampaignType.customDurationLabel,
    defaultMessage: en[CampaignType.customDurationLabel],
    description: "-",
  },
  [CampaignType.messagePlaceholder]: {
    id: CampaignType.messagePlaceholder,
    defaultMessage: en[CampaignType.messagePlaceholder],
    description: "-",
  },
  [CampaignType.titlePlaceholder]: {
    id: CampaignType.titlePlaceholder,
    defaultMessage: en[CampaignType.titlePlaceholder],
    description: "-",
  },
  [CampaignType.uploadFilePlaceholder]: {
    id: CampaignType.uploadFilePlaceholder,
    defaultMessage: en[CampaignType.uploadFilePlaceholder],
    description: "-",
  },
  [CampaignType.socialNetworksDescription]: {
    id: CampaignType.socialNetworksDescription,
    defaultMessage: en[CampaignType.socialNetworksDescription],
    description: "-",
  },
  [CampaignType.deliveryModeDescription]: {
    id: CampaignType.deliveryModeDescription,
    defaultMessage: en[CampaignType.deliveryModeDescription],
    description: "-",
  },
  [CampaignType.deliveryTypeDescription]: {
    id: CampaignType.deliveryTypeDescription,
    defaultMessage: en[CampaignType.deliveryTypeDescription],
    description: "-",
  },
  [CampaignType.nextBtn]: {
    id: CampaignType.nextBtn,
    defaultMessage: en[CampaignType.nextBtn],
    description: "-",
  },
  [CampaignType.cancelBtn]: {
    id: CampaignType.cancelBtn,
    defaultMessage: en[CampaignType.cancelBtn],
    description: "-",
  },
  [CampaignType.campaignDurationDescription]: {
    id: CampaignType.campaignDurationDescription,
    defaultMessage: en[CampaignType.campaignDurationDescription],
    description: "-",
  },
  [CampaignType.categoryPlaceholder]: {
    id: CampaignType.categoryPlaceholder,
    defaultMessage: en[CampaignType.categoryPlaceholder],
    description: "-",
  },
  [CampaignType.addCategoryBtn]: {
    id: CampaignType.addCategoryBtn,
    defaultMessage: en[CampaignType.addCategoryBtn],
    description: "-",
  },
  [CampaignType.campaignDurationHoverDescription]: {
    id: CampaignType.campaignDurationHoverDescription,
    defaultMessage: en[CampaignType.campaignDurationHoverDescription],
    description: "-",
  },
});
