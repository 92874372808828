export enum KeywordsType {
  Blocked = "BLOCK",
  Flagged = "FLAG",
}

export type KeyphraseLocationType = "CAPTION" | "TRANSCRIPT" | "HASHTAGS";

export interface TagElement {
  index: number;
  displayValue: string;
  id?: string;
}

export interface KeywordElement {
  content: string;
  policy: KeywordsType;
  id: string;
}

export interface PostKeyphrase {
  id: string;
  keyphraseId: number;
  content: string;
  policy: KeywordsType;
  location: KeyphraseLocationType;
}
