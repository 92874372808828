import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, Menu, MenuItem } from "semantic-ui-react";
import { UserContext } from "@socialcoach/services";
import { get } from "lodash";

import { PlayerSocialAccounts, TitleSection, SideBar } from "../../../components";
import { AppContext } from "../../../providers";
import { SettingsTags } from "../../../interfaces";
import { PlayerSignUpStep } from "../../../services";
import { redirectToPlayerStep } from "../../../utils";

import { WhiteContainer, ContentGrid } from "../../../styling/baseStyle";

import { PlayerMyCustomizations } from "../Customizations";
import { PlayerProfile } from "../Profile";

import "./styles.scss";
interface SettingMenu {
  title: string;
  activeSection: SettingsTags;
}
interface OwnProps {
  successHandler: (user: UserContext) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedPlayerSettings: React.FC<Props> = ({ history, location }) => {
  const { userContext } = React.useContext(AppContext);

  React.useEffect(() => {
    if (userContext?.signUpStep! !== PlayerSignUpStep[PlayerSignUpStep.PLAYER_BUSINESS_DATA]) {
      const url = redirectToPlayerStep(userContext?.signUpStep!);
      window.location.assign(url);
    }
  }, [userContext]);

  const [activeSection, setActiveSection] = React.useState<SettingsTags>(
    get(location, "state.activeSection", "SOCIAL_NETWORKS")
  );

  const menuList: SettingMenu[] = [
    {
      title: "Social Accounts",
      activeSection: "SOCIAL_NETWORKS",
    },
    {
      title: "Customizations",
      activeSection: "CUSTOMIZATIONS",
    },
    {
      title: "Profile",
      activeSection: "PROFILE",
    },
  ];

  const selectedContent = React.useMemo(() => {
    switch (activeSection) {
      case "SOCIAL_NETWORKS":
        return <PlayerSocialAccounts type={"PROFILE"} showTitle={false} deleteEnable={true} />;
      case "CUSTOMIZATIONS":
        return <PlayerMyCustomizations />;
      case "PROFILE":
        return <PlayerProfile />;
    }
  }, [activeSection]);

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid>
        <Grid columns={1} className={"socialContent"}>
          <TitleSection title={`Settings`} ready={true} showNextOption={false} showSeparator={false} />

          <Menu pointing secondary className="internalTapMenu">
            {menuList.map(element => {
              return (
                <MenuItem
                  name={element.title}
                  active={activeSection === element.activeSection}
                  onClick={() => setActiveSection(element.activeSection)}
                />
              );
            })}
          </Menu>

          <Grid.Column width={activeSection !== "SOCIAL_NETWORKS" ? 16 : 8} className={"noPadding"}>
            {selectedContent}
          </Grid.Column>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const PlayerSettings = withRouter(IntegratedPlayerSettings);
