import * as React from "react";
import { Grid, Header, Image, TextArea } from "semantic-ui-react";
import Linkify from "react-linkify";

import { Svg } from "../../assets";
import { CustomModal } from "../CustomModal";
import { ClientNotes, PlayerAccount, UserGroups } from "../../interfaces";
import { AppContext } from "../../providers";
import { VideoProjectServices } from "../../services";
import { fullNameFrom } from "../../utils";

import "./styles.scss";
interface Props {
  playerInfo?: PlayerAccount;
  clientNotes?: ClientNotes;
  showPhone?: boolean;
}

const PlayerInfoSectionFC: React.FC<Props> = ({ playerInfo, clientNotes, showPhone }) => {
  const { userContext } = React.useContext(AppContext);

  const [showNotesModal, setShowNotesModal] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<string>(clientNotes ? clientNotes.notes : "");
  const [savedNotes, setSavedNotes] = React.useState<string>(clientNotes ? clientNotes.notes : "");

  const isEditor = userContext?.account.groups.some(
    (g: UserGroups) => g === UserGroups.EDITOR || g === UserGroups.ADMIN
  );

  const clientNotesTextArea = (disabled: boolean): JSX.Element => {
    return disabled ? (
      <Linkify>{notes}</Linkify>
    ) : (
      <TextArea
        data-elm-id={"playerInfoClientNotesInput"}
        className="clientNotesField"
        placeholder={"Add client notes..."}
        rows={3}
        disabled={disabled}
        value={notes}
        onChange={data => {
          setNotes(data.currentTarget.value);
        }}
      />
    );
  };

  const updateClientNotes = () => {
    VideoProjectServices.addClientNotes(playerInfo?.playerId!!, notes).then(() => {
      setShowNotesModal(false);
      setSavedNotes(notes);
    });
  };

  const withNotes = savedNotes !== "";
  return (
    <>
      <Grid.Row className={"containerPlayerInfoSection"}>
        <Image circular wrapped size="tiny" src={playerInfo?.photoUrl || Svg.noImageuser} />
        <div className="smallPaddingLeft">
          <Header as="h3" textAlign="left" className="noMargin noPadding">
            {playerInfo ? fullNameFrom(playerInfo!) : ""}
          </Header>
          <Header as="p" textAlign="left" className="noMargin noPadding">
            {playerInfo ? playerInfo.email : ""}
          </Header>
          {showPhone && (
            <Header as="p" textAlign="left" className="noMargin noPadding">
              {playerInfo?.phone || ""}
            </Header>
          )}
        </div>
      </Grid.Row>

      {isEditor && !withNotes && (
        <Grid.Row>
          <span
            className={"rightPadding skip-link"}
            data-elm-id={"addClientNotesBtn"}
            onClick={() => setShowNotesModal(true)}
          >
            Add Client Notes
          </span>
        </Grid.Row>
      )}

      {isEditor && withNotes && (
        <Grid.Row>
          <Grid>
            <Grid.Row>
              <Grid className="clientNotesTitleGrid">
                <Grid.Column width={7}>
                  <p>Client Notes</p>
                </Grid.Column>
                <Grid.Column width={9} floated="right" className="edit-link">
                  <span data-elm-id={"editClientNotesBtn"} onClick={() => setShowNotesModal(true)}>
                    Edit
                  </span>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row className="clientNotesClickeable">{clientNotesTextArea(true)}</Grid.Row>
          </Grid>
        </Grid.Row>
      )}

      {showNotesModal && (
        <CustomModal
          title="Edit Client Notes"
          acceptLabel="Save"
          acceptHandler={updateClientNotes}
          rejectHandler={() => setShowNotesModal(false)}
        >
          {clientNotesTextArea(false)}
        </CustomModal>
      )}
    </>
  );
};

export const PlayerInfoSection = PlayerInfoSectionFC;
