import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Grid, Loader } from "semantic-ui-react";

import { SideBar, TitleSection, ConfirmationModal } from "../../../components";
import { VideoProjectRequest, WebComponents, dateKey, timeKey } from "../../../interfaces";
import { AppContext } from "../../../providers";
import { URLS, coachWithWritePermission, getSocialNetworkMessages, promptSpecificDate } from "../../../utils";
import { VideoProjectServices } from "../../../services";

import { VideoProjectForm } from "./components/VideoProjectForm";
import { modalReducer } from "./reducer";

import { VideoProjectPageType, descriptors } from "./descriptors";
import { WhiteContainer } from "../../../styling/baseStyle";
import "./styles.scss";

type Props = RouteComponentProps;

const VideoProjectFC: React.FC<Props> = ({ history, location }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);
  const writePermission = coachWithWritePermission(WebComponents.VIDEO_CATALYST, userContext);

  const [state, dispatch] = React.useReducer(modalReducer, {
    open: false,
    status: "ACTIVE",
    duplicated: false,
    action: "close",
  });

  // Properties
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formErrorMessages, setFormErrorMessages] = React.useState<string[] | undefined>(undefined);
  const [incomplete, setIncomplete] = React.useState<boolean>(true);
  const [uploadingContent, setUploadingContent] = React.useState(false);
  const [videoProject, setVideoProject] = React.useState<any>({});

  // Location params
  const searchParams = new URLSearchParams(location.search);
  const preloadedPlayerId = searchParams.get("playerId");

  React.useEffect(() => {
    const { isAutoPost, socialNetworksCounter, withMessage, playerId, withMediaReady } = videoProject;

    // TODO: Apply De morgan
    const withError =
      playerId === null ||
      (isAutoPost && socialNetworksCounter < 1) ||
      withMessage === undefined ||
      (withMessage !== undefined && withMessage.trim() === "") ||
      withMediaReady === undefined;
    setIncomplete(withError);
  }, [videoProject]);

  const createVideoProject = async (): Promise<any> => {
    if (videoProject) {
      setLoading(true);

      const {
        playerId,
        allowShortLink,
        allowComplianceDisclaimer,
        allowHashtags,
        isAutoPost,
        status,
        originalMediaGroup,
        withMessage,
        initialMessages,
      } = videoProject;

      const preferredPostDate =
        videoProject[dateKey] && videoProject[timeKey]
          ? promptSpecificDate(videoProject[dateKey], videoProject[timeKey])!
          : undefined;

      const snFiltered = Object.keys(videoProject.socialNetworkMessages).filter(
        key => videoProject.socialNetworkMessages[key]
      );
      const socialNetworkMessages = getSocialNetworkMessages(snFiltered);

      const request: VideoProjectRequest = {
        playerId,
        coachId: userContext!!.coach!!.id,
        socialNetworkMessages,
        messageToPost: withMessage,
        originalMediaGroup,
        status,
        isAutoPost,
        preferredPostDate,
        allowShortLink,
        allowComplianceDisclaimer,
        initialMessages,
        allowPlayerHashtags: allowHashtags,
      };

      VideoProjectServices.create(request)
        .then(() => {
          setLoading(false);
          history.goBack();
        })
        .catch(error => {
          setLoading(false);
          setIncomplete(false);
          setUploadingContent(false);
          setFormErrorMessages([error.substring(0, 100)]);
        });
    }
  };

  const closeAndGoToVideoCatalyst = () => {
    if (state.action === "cancel") {
      history.push(URLS.coach.videoCatalyst);
    } else {
      dispatch({ type: "close" });
    }
  };

  const cancelAction = () => {
    dispatch({ type: "open_cancel", status: "ACTIVE" });
  };

  const confirmationModal = (): JSX.Element => {
    const modalTitle = formatMessage({
      ...descriptors[VideoProjectPageType.cancelAlertTitle],
    });
    return (
      <ConfirmationModal
        title={!state.duplicated ? modalTitle : undefined}
        message={""}
        openConfirmationModal={state.open}
        onClose={() => dispatch({ type: "close" })}
        okHandler={() => {
          dispatch({ type: "close" });
          if (!incomplete) {
            createVideoProject();
          }
        }}
        rejectHandler={closeAndGoToVideoCatalyst}
      />
    );
  };

  const customLinkSection = (disableButtons: boolean): JSX.Element => (
    <Grid.Column width={7} floated="right" className="skip-link noMarginRight">
      <span className={"rightPadding"} data-elm-id={"videoProjectPageCancelBtn"} onClick={cancelAction}>
        {formatMessage({ ...descriptors[VideoProjectPageType.cancelBtn] })}
      </span>
    </Grid.Column>
  );

  const content = (): JSX.Element => {
    return (
      <Grid columns={1} className={"contentSection"}>
        {confirmationModal()}
        <TitleSection
          title={formatMessage({ ...descriptors[VideoProjectPageType.title] })}
          ready={true}
          customLinkSection={customLinkSection(uploadingContent)}
          customNextLabel={formatMessage({ ...descriptors[VideoProjectPageType.saveBtn] })}
          handleNextAction={() => createVideoProject()}
          disableButtons={uploadingContent || incomplete || !writePermission}
          titleSize={8}
          buttonsSize={8}
          nextButtonSize={7}
          showLinkOption={writePermission}
          showNextOption={writePermission}
        />
        <Grid.Row columns={1} className={"formSection leftPadding"}>
          <Grid className={"infoSection"}>
            <Grid.Column width={16} className={"formContainer"}>
              <VideoProjectForm
                disabled={!writePermission}
                modeEditable={false}
                videoProject={videoProject}
                onContentloading={setUploadingContent}
                updatingHandler={setVideoProject}
                pageErrors={formErrorMessages}
                coachId={userContext?.coach?.id!!}
                playerId={preloadedPlayerId}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />
      {loading ? <Loader active size="large" /> : content()}
    </WhiteContainer>
  );
};

export const VideoProjectPage = withRouter(VideoProjectFC);
