import { defineMessages } from "react-intl";

export enum VideoProjectBubbleDetailsType {
  title = "VideoProjectBubbleDetailsType.title",
  submit = "VideoProjectBubbleDetailsType.submit",
}

export const en = {
  [VideoProjectBubbleDetailsType.title]: " Messages",
  [VideoProjectBubbleDetailsType.submit]: "Submit",
};

export const descriptors = defineMessages({
  [VideoProjectBubbleDetailsType.title]: {
    id: VideoProjectBubbleDetailsType.title,
    defaultMessage: en[VideoProjectBubbleDetailsType.title],
    description: "Log In title",
  },
  [VideoProjectBubbleDetailsType.submit]: {
    id: VideoProjectBubbleDetailsType.submit,
    defaultMessage: en[VideoProjectBubbleDetailsType.submit],
    description: "Sign In text",
  },
});
