import * as React from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { get } from "lodash";

import { Media, CoachSettings, VideoProjectRequest } from "../../../../../interfaces";
import { VideoProjectComponent } from "../../../../../components";
import { SettingsServices } from "../../../../../services";
import { URLS } from "../../../../../utils";

import { validationSchema } from "./validations";
export interface OwnProps {
  /**
   * Video Project Info
   */
  videoProject?: VideoProjectRequest;
  /**
   * Boolean to indicate if the form is editable (just some fields)
   */
  modeEditable: boolean;
  disabled: boolean;
  pageErrors?: string[];

  coachId: string;
  playerId?: string | null;
  updatingHandler: (values: any) => void;
  onContentloading?: (value: boolean) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedVideoProject: React.FC<Props> = ({
  modeEditable,
  disabled,
  videoProject,
  updatingHandler,
  onContentloading,
  pageErrors,
  history,
  coachId,
  playerId,
}) => {
  const [attachedLoading, setAttachedLoading] = React.useState(false);
  const [videoLoading, setVideoLoading] = React.useState(false);
  const [showHashtagToggle, setShowHashtagToggle] = React.useState(false);

  React.useEffect(() => {
    if (onContentloading) {
      onContentloading(attachedLoading || videoLoading);
    }
  }, [attachedLoading, videoLoading, onContentloading]);

  React.useEffect(() => {
    const getSettings = async () => {
      SettingsServices.getAppSettingsByCoachId(coachId).then(response => {
        const hashtagSettingStatus =
          response.settings.find(s => s.appSettingId === CoachSettings.PLAYER_HASHTAG)?.status || "ACTIVE";
        setShowHashtagToggle(hashtagSettingStatus === "ACTIVE");
      });
    };

    if (coachId) {
      getSettings();
    }

    getSettings();
  }, [coachId]);

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
        initialValues={{
          playerId: get(videoProject, "playerId", playerId),
          messageToPost: get(videoProject, "messageToPost", ""),
          hashtags: get(videoProject, "hashtags", ""),
          allowShortLink: get(videoProject, "allowShortLink", true),
          allowComplianceDisclaimer: get(videoProject, "allowComplianceDisclaimer", false),
          allowHashtags: get(videoProject, "allowHashtags", true),
          originalMediaGroup: get(videoProject, "originalMediaGroup", []),
          withMediaReady: get(videoProject, "originalMedia.length", 0),
          withMessage: get(videoProject, "withMessage", ""),
          status: get(videoProject, "status", "SUBMITTED_FOR_EDITING"),
          isAutoPost: get(videoProject, "isAutoPost", true),
          socialNetworkMessages: get(videoProject, "socialNetworkMessages", []),
          socialNetworksCounter: get(videoProject, "socialNetworksCounter", 0),
          initialMessages: get(videoProject, "initialMessages", []),
          autoCaption: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async () => {
          // Action removed because the client wants
          // to use TitleSection action to save the prompt
        }}
      >
        {({ values, errors, isSubmitting, handleChange, setFieldValue, handleSubmit }) => {
          updatingHandler(values);

          return (
            <VideoProjectComponent
              messages={{
                error: pageErrors && pageErrors.length > 0 ? pageErrors : (Object.values(errors) as string[]),
              }}
              handleCancel={() => {
                history.push(URLS.coach.videoCatalyst);
              }}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleSetFieldValue={setFieldValue}
              handleAttachedFileUploadStarted={() => setAttachedLoading(true)}
              handleAttachedFileUploadFinished={() => setAttachedLoading(false)}
              handleMediaUpdated={(newMedia: Media[]) => {
                setFieldValue("withMediaForPost", newMedia.length);
                setFieldValue("originalMediaGroup", {
                  media: newMedia.map((item, sortOrder) => {
                    const newMediaObj = {
                      ...item,
                      sortOrder,
                    };
                    return newMediaObj;
                  }),
                  mediaGroupId: get(values.originalMediaGroup, "mediaGroupId", null),
                  type: "MIX",
                });
              }}
              showHashtagToggle={showHashtagToggle}
              disabled={disabled}
              modeEditable={modeEditable}
              values={values}
              isSubmitting={isSubmitting}
              selectedPlayer={playerId ? true : false}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export const VideoProjectForm = withRouter(IntegratedVideoProject);
