import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useIntl } from "react-intl";
import { Menu, MenuItem } from "semantic-ui-react";
import { get } from "lodash";

import { SideBar, TitleSection } from "../../../components";
import { AppContext } from "../../../providers";
import { LibraryTags, PricePlanTier } from "../../../interfaces";
import { URLS } from "../../../utils";

import { WhiteContainer, ContentGrid, SocialGrid, SocialGridColumn } from "../../../styling/baseStyle";
import { PlayerLibraryListContent } from "../../../components/Lists/PlayerLibraryListContent";
import { PlayerVideoScriptsSection } from "../VideoScripts";
import { PlayerVideoCatalyst } from "../VideoCatalystList";

import { LibraryType, descriptors } from "./descriptors";
import { RowContainer } from "./styled";
import "./styles.scss";

interface SettingMenu {
  title: string;
  activeSection: LibraryTags;
}

type Props = RouteComponentProps;

const LibraryFC: React.FC<Props> = ({ history, location }) => {
  const { formatMessage } = useIntl();
  const { userContext } = React.useContext(AppContext);

  const myCoachId = get(userContext, "subscriptions[0].pricePlan.coachId", undefined);
  const playerId = get(userContext, "player.id", undefined);
  const subscriptionType = get(userContext, "subscriptions[0].pricePlan.tier", "");
  const isSocialCoachPlus = subscriptionType === PricePlanTier.PLAYER_PLUS;
  const [activeSection, setActiveSection] = React.useState<LibraryTags>(
    get(location, "state.activeSection", "PROMPTS")
  );

  const [menuList, setMenuList] = React.useState<SettingMenu[]>([
    {
      title: "Prompts",
      activeSection: "PROMPTS",
    },
  ]);

  React.useEffect(() => {
    let menu: SettingMenu[] = [
      {
        title: "Prompts",
        activeSection: "PROMPTS",
      },
    ];
    if (isSocialCoachPlus) {
      menu = menu.concat([
        {
          title: "Video Catalyst",
          activeSection: "VIDEO_CATALYST",
        },
        {
          title: "Scripts",
          activeSection: "SCRIPTS",
        },
      ]);
    }

    setMenuList(menu);
  }, [isSocialCoachPlus]);

  const selectedContent = React.useMemo(() => {
    switch (activeSection) {
      case "PROMPTS":
        return <PlayerLibraryListContent coachId={myCoachId} playerId={playerId} />;
      case "SCRIPTS":
        return (
          <PlayerVideoScriptsSection
            handleNextAction={() => {
              history.push(URLS.player.newVideoScript);
            }}
          />
        );
      case "VIDEO_CATALYST":
        return (
          <PlayerVideoCatalyst
            myCoachId={myCoachId}
            playerId={playerId!!}
            addCampaignHandler={() => {
              history.push(URLS.player.newVideoProject);
            }}
          />
        );
    }
  }, [activeSection]);

  return (
    <WhiteContainer>
      <SideBar history={history} />
      <ContentGrid className={"listManagerContent"} columns={1}>
        <SocialGrid columns={1}>
          <TitleSection
            title={formatMessage({ ...descriptors[LibraryType.title] })}
            ready={true}
            showNextOption={false}
            showSeparator={false}
            titleSize={5}
          />
          <Menu pointing secondary className="internalTapMenu">
            {menuList.map(element => {
              return (
                <MenuItem
                  name={element.title}
                  active={activeSection === element.activeSection}
                  onClick={() => setActiveSection(element.activeSection)}
                />
              );
            })}
          </Menu>

          <RowContainer className="playerListRowContainer">
            <SocialGridColumn rightpadding={"0"}>{selectedContent}</SocialGridColumn>
          </RowContainer>
        </SocialGrid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const PlayerLibrary = withRouter(LibraryFC);
