import * as Yup from "yup";
import { Rules } from "../../../../../utils/rules";

export const validationSchema = Yup.object().shape(
  {
    withMessage: Yup.string().when("withMediaForPost", {
      is: (a: number) => a === 0,
      then: () => Yup.string()
        .trim()
        .max(Rules.MAX_CONTENT, "Caption is too long")
        .required("Caption, Hashtags, or Image/Video for Post is required"),
      otherwise: () => Yup.string()
        .trim()
        .max(Rules.MAX_CONTENT, "Caption is too long"),
    }),
   withMediaForPost: Yup.number().when("withMessage", {
    is: (m: any) => m === "" || m === undefined,
    then: () => Yup.number().min(1, "Caption, Hashtags, or Image/Video for Post is required"),
    otherwise: () =>  Yup.number(),
  }),
  },
  [["withMessage", "withMediaForPost"]]
);
